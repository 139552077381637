<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  > 
  <v-col cols="12" md="8">
    <v-container v-if="news" fluid>
      <v-row class="mb-6 mt-15" justify="space-around">
        <v-col
          v-for="(item, i) in [news]"
          :key="item.title + i"
          cols="12"
          class="pt-0 mb-10"
        >
          <v-card class="border-radius-xl py-5 text-center">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  position="top center"
                  fill
                  height="100%"
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>
            <div class="px-5 mt-3 mb-15">
              <v-row align="center" justify="center" class="h-100">
                <div class="mt-5">
                  <h2 class="font-weight-normal text-typo text-h2 px-4">
                    {{ item.title }}
                  </h2>
                </div>
              </v-row>
            </div>
            <div>
              <p
                class="mx-5 text-start text-typo"
                v-html="item.description"
              ></p>
            </div>
            <hr class="horizontal dark mb-6" />
            <div class="">
              <p class="my-auto text-body font-weight-light">
                {{ item.manager.username }}
              </p>
            </div>
            <div class="">
              <p class="my-auto text-body font-weight-light">
                {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="border-radius-xl mt-5">
        <v-card-text class="card-padding">
          <v-expansion-panels flat color="#ddd" class="mb-8">
            <v-expansion-panel class="bg-transparent">
              <v-expansion-panel-header class="border-bottom px-0">
                <h5 class="text-h5 font-weight-bold text-body">
                  {{ newsComments.length }} Kommentare
                </h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="newsComments.length == 0">
                  <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
                    Keine Kommentare vorhanden
                  </h5>
                </div>
                <div v-else>
                  <div class="d-flex mt-5">
                    <div class="flex-grow-1 ms-4">
                      <div
                        :key="index"
                        v-for="(comment, index) in newsComments"
                      >
                        <v-list-item
                          :ripple="false"
                          class="px-0 border-radius-sm mb-2"
                        >
                          <v-avatar
                            width="48"
                            height="48"
                            class="shadow border-radius-lg me-4"
                          >
                            <img
                              v-if="comment.user.avatar"
                              :src="getImage(comment.user.avatar)"
                              alt="Avatar"
                              class="border-radius-lg"
                            />
                            <div v-else>
                              {{ comment.user.username.charAt(0)
                              }}{{ comment.user.username.charAt(1) }}
                            </div>
                          </v-avatar>
                          <v-list-item-content>
                            <div class="d-flex align-center">
                              <div>
                                <h6
                                  class="
                                    mb-0
                                    text-sm text-sm text-typo
                                    font-weight-bold
                                  "
                                >
                                  {{ comment.user.username }}
                                </h6>
                                <p
                                  class="
                                    mb-0
                                    text-xs text-body
                                    font-weight-light
                                  "
                                >
                                  {{ comment.comment }}
                                </p>
                              </div>
                              <div class="ms-auto"></div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                        <p class="text-xs mt-2 font-weight-light text-body">
                          {{ comment.created | moment("DD.MM.YYYY HH:mm") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <v-row class="mt-0 ml-1" justify="space-between">
            <h5 class="font-weight-bolder text-h5 text-typo mb-10" v-if="newsComments.length > 0">
              {{ newsComments.length }} Kommentare
            </h5>
            <div v-else>

            </div>
            <div class="mr-2">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="$store.dispatch('getNewsComments', news._id)"
                    v-bind="attrs"
                    v-on="on"
                    class="material-icons-round text-primary ms-auto"
                    size="30"
                  >
                    refresh
                  </v-icon>
                </template>
                <span>Aktualisieren</span>
              </v-tooltip>
            </div>
          </v-row> -->

          <!-- <div v-if="newsComments.length == 0">
            <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
              Keine Kommentare vorhanden
            </h5>
          </div>
          <div v-else>
            <div class="d-flex">
              <div class="flex-grow-1 ms-4">
                <div :key="index" v-for="(comment, index) in newsComments">
                  <v-list-item
                    :ripple="false"
                    class="px-0 border-radius-sm mb-2"
                  >
                    <v-avatar
                      width="48"
                      height="48"
                      class="shadow border-radius-lg me-4"
                    >
                      <img
                        v-if="comment.user.avatar"
                        :src="getImage(comment.user.avatar)"
                        alt="Avatar"
                        class="border-radius-lg"
                      />
                      <div v-else>
                        {{ comment.user.username.charAt(0)
                        }}{{ comment.user.username.charAt(1) }}
                      </div>
                    </v-avatar>
                    <v-list-item-content>
                      <div class="d-flex align-center">
                        <div>
                          <h6
                            class="
                              mb-0
                              text-sm text-sm text-typo
                              font-weight-bold
                            "
                          >
                            {{ comment.user.username }}
                          </h6>
                          <p class="mb-0 text-xs text-body font-weight-light">
                            {{ comment.comment }}
                          </p>
                        </div>
                        <div class="ms-auto"></div>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                  <p class="text-xs mt-2 font-weight-light text-body">
                    {{ comment.created | moment("DD.MM.YYYY HH:mm") }}
                  </p>
                </div>
              </div>
            </div>
          </div> -->
        </v-card-text>
      </v-card>
      <h2
        class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
        style="font-size: 35px"
        v-if="eventNews.length > 0"
      >
        Weitere Neuigkeiten
      </h2>
      <v-row class="mb-6 mt-15" justify="space-around">
        <v-col
          v-for="(item, i) in eventNews.filter(
            (eventNews) => eventNews._id != news._id
          )"
          :key="item.title + i"
          lg="4"
          md="6"
          sm="12"
          cols="12"
          class="pt-0 mb-0"
        >
          <news-card :item="item"></news-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!news && !loadingEventNews">
      <h2
        class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
        style="font-size: 35px"
      >
        Hoppla.... <br />
        Wir haben diese News nicht gefunden...
      </h2>
      <v-row justify="center">
        <div>
          <v-img
            height="100%"
            width="400px"
            :src="errorImage"
            class="img-fluid border-radius-lg"
            :alt="errorImage"
          />
        </div>
      </v-row>
    </v-container>
    <sponsors></sponsors>

    <faq></faq>
  </v-col>
  </v-row>
</template>
<script>
import NewsCard from "@/components/Widgets/NewsCard.vue";
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
export default {
  name: "NewsDetails",
  components: {
    NewsCard,
    Sponsors,
    faq,
  },
  computed: {
    loadingEventNews(){
      return this.$store.getters.loadingEventNews;
    },
    news() {
      return this.$store.getters.news;
    },
    eventNews() {
      return this.$store.getters.eventNews;
    },
    newsComments() {
      return this.$store.getters.newsComments;
    },
  },
  mounted() {
    this.$store.dispatch("getEventNewsByID", this.$route.params.id);
    this.$store.dispatch("getEventNews");
    window.scrollTo(0, 0);
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
      brands: [
        {
          name: "Oelsnitz Media",
          image: require("@/assets/img/logos/sponsors/Oelsnitz-media.jpg"),
          link: "https://www.oelsnitz.media/",
        },
        {
          name: "Gewerbeverbank Oelsnitz/Vogtl. e.V.",
          image: require("@/assets/img/logos/sponsors/gvov.png"),
          link: "https://gvov.oelsnitz-vogtland-info.de/",
        },
        {
          name: "Oelsnitzer Kultur GmbH",
          image: require("@/assets/img/logos/sponsors/okg-logo.png"),
          link: "https://www.oelsnitz.de/",
        },
        {
          name: "Sparkasse Vogtland",
          image: require("@/assets/img/logos/sponsors/spk-logo-desktop.png"),
          link: "https://www.sparkasse-vogtland.de/",
        },
        {
          name: "eatDorado",
          image: require("@/assets/img/logos/sponsors/eatDoradoLogo.png"),
          link: "https://www.eatdorado.de/",
        },
        {
          name: "Kathrins",
          image: require("@/assets/img/logos/sponsors/kathrins.gif"),
          link: "https://www.facebook.com/Kathrins.Delicious.Food/",
        },
        {
          name: "Stadt Oelsnitz/Vogtl.",
          image: require("@/assets/img/logos/sponsors/oelsnitz.jpeg"),
          link: "https://www.oelsnitz.de/",
        },
        {
          name: "Meiser",
          image: require("@/assets/img/logos/sponsors/meiser-logo.png"),
          link: "https://www.meiser.de/de/",
        },
        {
          name: "Optiplan",
          image: require("@/assets/img/logos/sponsors/optiplan.png"),
          link: "https://optiplan.eu/de/index.html",
        },
        {
          name: "Sternquell",
          image: require("@/assets/img/logos/sponsors/sternquell.png"),
          link: "https://www.sternquell.de/",
        },
        {
          name: "Morgner",
          image: require("@/assets/img/logos/sponsors/morgner.jpeg"),
          link: "https://www.morgner-heizung.de/",
        },
      ],
      panels: [
        {
          title: "How do I order?",
          description:
            "We’re not always in the position that we want to be at. We’re constantly growing. We’re constantly making mistakes. We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed.",
        },
        {
          title: "How can i make the payment?",
          description:
            "It really matters and then like it really doesn’t matter. What matters is the people who are sparked by it. And the people who are like offended by it, it doesn’t matter. Because it's about motivating the doers. Because I’m here to follow my dreams and inspire other people to follow their dreams, too. We’re not always in the position that we want to be at. We’re constantly growing. We’re constantly making mistakes. We’re constantly trying to express ourselves and actualize our dreams. If you have the opportunity to play this game of life you need to appreciate every moment. A lot of people don’t appreciate the moment until it’s passed.",
        },
        {
          title: "How much time does it take to receive the order?",
          description:
            "The time is now for it to be okay to be great. People in this world shun people for being great. For being a bright color. For standing out. But the time is now to be okay to be the greatest you. Would you believe in what you believe in, if you were the only one who believed it? If everything I did failed - which it doesn't, it actually succeeds - just the fact that I'm willing to fail is an inspiration. People are so scared to lose that they don't even try. Like, one thing people can't say is that I'm not trying, and I'm not trying my hardest, and I'm not trying to do the best way I know how.",
        },
        {
          title: "Can I resell the products?",
          description:
            "I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! They're slowed down by their perception of themselves. If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything. If everything I did failed - which it doesn't, it actually succeeds - just the fact that I'm willing to fail is an inspiration. People are so scared to lose that they don't even try. Like, one thing people can't say is that I'm not trying, and I'm not trying my hardest, and I'm not trying to do the best way I know how.",
        },
        {
          title: "Where do I find the shipping details?",
          description:
            "There’s nothing I really wanted to do in life that I wasn’t able to get good at. That’s my skill. I’m not really specifically talented at anything except for the ability to learn. That’s what I do. That’s what I’m here for. Don’t be afraid to be wrong because you can’t learn anything from a compliment. I always felt like I could do anything. That’s the main thing people are controlled by! Thoughts- their perception of themselves! They're slowed down by their perception of themselves. If you're taught you can’t do anything, you won’t do anything. I was taught I could do everything.",
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <div class="d-sm-flex justify-between mb-5">
      <div>
        <v-dialog v-model="dialogPushnotification" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-normal
                text-capitalize
                ms-auto
                btn-primary
                bg-gradient-primary
                py-3
                px-6
                ms-3
              "
              >Neue Pushnachricht</v-btn
            >
          </template>
          <v-card class="card-shadow border-radius-xl">
            <div class="card-header-padding card-border-bottom">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Pushnachricht erstellen</span
              >
            </div>
            <v-card-text class="card-padding">
              <v-container class="pa-0">
                <p class="ma-0 mb-4 text-danger font-weight-bold">
              Hinweis: Senden Sie Pushnachrichten mit bedacht! Zu häufig
              gesendete Nachrichten können abschreckend auf einige Nutzer
              wirken!
            </p>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="newPushnotification.title"
                      hide-details
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      "
                      dense
                      flat
                      outlined
                      height="43"
                      placeholder="Titel"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="newPushnotification.message"
                      hide-details
                      rows="3"
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                        input-icon
                      "
                      dense
                      flat
                      outlined
                      placeholder="Nachricht"
                    ></v-textarea>
                  </v-col>
                  <v-col>
                    <v-checkbox
                      v-model="newPushnotification.direct"
                      color="#141727"
                      :ripple="false"
                      class="ma-0 checkbox-custom checkbox-thinner"
                      hide-details
                    >
                      <template v-slot:label>
                        <span class="text-body text-sm ls-0"
                          >Pushnachricht direkt senden
                        </span>
                      </template>
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions class="card-padding d-flex justify-end">
              <v-btn
                @click="dialogPushnotification = false"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-normal
                  text-capitalize
                  btn-ls btn-secondary btn-outline-secondary
                  shadow-none
                  bg-transparent
                  py-3
                  px-6
                "
                >Abbrechen</v-btn
              >

              <v-btn
                @click="savePushnotification"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-normal
                  text-capitalize
                  btn-ls btn-primary
                  bg-gradient-primary
                  py-3
                  px-6
                "
                >Speichern</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <v-row class="mt-0 ml-1" justify="space-between">
          <h5 class="font-weight-bolder text-h5 text-typo">
            {{
              eventNotifications.filter(
                (eventNotification) =>
                  eventNotification.type == "event" &&
                  eventNotification.event == event._id
              ).length
            }}
            Gesendete Pushnachrichten
          </h5>
          <div class="mr-2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  @click="$store.dispatch('getEventNotifications')"
                  v-bind="attrs"
                  v-on="on"
                  class="material-icons-round text-primary ms-auto"
                  size="30"
                >
                  refresh
                </v-icon>
              </template>
              <span>Aktualisieren</span>
            </v-tooltip>
          </div>
        </v-row>
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="
            eventNotifications.filter(
              (eventNotification) =>
                eventNotification.type == 'event' &&
                eventNotification.event == event._id
            )
          "
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:item.title="{ item }">
            <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                {{ item.title }}
              </span>
            </div>
          </template>

          <template v-slot:item.message="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.message }}
            </span>
          </template>

          <template v-slot:item.image="{ item }">
            <v-list-item-avatar rounded :size="36" class="my-0 me-4">
              <v-img :alt="item.image" :src="item.image"></v-img>
            </v-list-item-avatar>
          </template>

          <template v-slot:item.created="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.created | moment("DD.MM.YYYY HH:mm") }}
            </span>
          </template>

          <template v-slot:item.timestamp="{ item }">
            <span
              class="text-sm font-weight-normal text-body"
              v-if="item.timestamp"
            >
              {{ item.timestamp | moment("DD.MM.YYYY HH:mm") }}
            </span>
          </template>

          <template v-slot:item._id="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">search</v-icon>
            </v-btn>

            <v-btn
              @click="showDeleteDialog(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round text-danger"
                >delete</v-icon
              >
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#D81B60"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-snackbar
      top
      v-model="snackbar.visible"
      :color="snackbar.color"
      class="snackbar-shadow"
    >
      <div class="d-flex align-start alert-notify">
        <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
          >notifications</v-icon
        >
        <p class="mb-0">
          <span class="font-size-root font-weight-600">{{
            snackbar.title
          }}</span>
          <br />
          {{ snackbar.message }}
        </p>
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          icon
          elevation="0"
          max-width="136"
          :ripple="false"
          height="43"
          class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
          color="rgba(255,255,255, .85)"
          @click="snackbar.visible = false"
          v-bind="attrs"
        >
          <v-icon size="13">fas fa-times</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">{{
            formTitle
          }}</span>
        </div>
        <v-card-text class="card-padding">
          <v-container class="pa-0">
            <p class="ma-0 mb-4 text-danger font-weight-bold">
              Hinweis: Senden Sie Pushnachrichten mit bedacht! Zu häufig
              gesendete Nachrichten können abschreckend auf einige Nutzer
              wirken!
            </p>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.title"
                  hide-details
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  outlined
                  height="43"
                  placeholder="Titel"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.message"
                  hide-details
                  rows="3"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    input-icon
                  "
                  dense
                  flat
                  outlined
                  placeholder="Nachricht"
                ></v-textarea>
              </v-col>
              <v-col>
                <v-checkbox
                  v-model="editedItem.direct"
                  color="#141727"
                  :ripple="false"
                  class="ma-0 checkbox-custom checkbox-thinner"
                  hide-details
                >
                  <template v-slot:label>
                    <span class="text-body text-sm ls-0"
                      >Pushnachricht senden
                    </span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="close"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-outline-secondary
              bg-transparent
              py-3
              px-6
            "
            >schließen</v-btn
          >

          <v-btn
            @click="save"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >speichern {{ editedItem.direct ? " & senden" : "" }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <delete-dialog :open="dialogDelete" @close="closeDelete" @confirm="deleteItemConfirm"></delete-dialog>
  </div>
</template>
<script>
import users from "./Users";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";
import DeleteDialog from '@/components/DeleteDialog.vue'
export default {
  name: "paginated-tables",
  mixins: [coreData],
  components: {
    DeleteDialog
  },
  data() {
    return {
      filter: "",
      dialogNoInvoice: false,
      showParticipantsDialog: false,
      dialogPushnotification: false,
      dialogDeleteComment: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      users,
      search: "",
      editedIndex: -1,
      editedItemDelete: {},
      editedCommentDelete: {},
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      headers: [
        {
          text: "Titel",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "title",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Nachricht",
          value: "message",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Erstellt",
          value: "created",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Gesendet",
          value: "timestamp",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Aktionen",
          value: "_id",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showParticipants(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.showParticipantsDialog = true;
    },
    
    close() {
      this.dialog = false;
      this.showParticipantsDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    showDeleteDialog(item){
      this.editedItemDelete = item
      this.dialogDelete = true
    },

    showDeleteCommentDialog(item){
      this.editedCommentDelete = item
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.deleteItem()
    },

    closeDelete(){
      this.editedItemDelete = null;
      this.dialogDelete = false;
    },

    deleteItem() {
      if(!this.editedItemDelete) return;
      axiosAuth
        .delete("eventNotifications/" + this.editedItemDelete._id)
        .then(() => {
          this.uploadComplete = true;
          this.$store.commit("updating", false);
          // this.$store.dispatch("getEventNotifications");
          this.SnackbarShow(
            "Success",
            "Aktion erfolgreich!",
            "Pushnachricht erfolgreich gelöscht!"
          );
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit("updating", false);
          this.SnackbarShow("Danger", "Fehler!");
        });
    },

    save() {
      this.updateEventNotification(this.editedItem);
      var message = "Pushnachricht wurde gespeichert";
      if (this.editedItem.direct) {
        message = "Pushnachricht wurde gespeichert & gesendet";
      }
      this.SnackbarShow("Success", "Aktion erfolgreich!", message);
      this.close();
    },

    savePushnotification() {
      var payload = {
        title: this.newPushnotification.title,
        message: this.newPushnotification.message,
        event: this.event._id,
        type: "event",
        image: this.event.img,
        topic: "events",
        manager: this.userInformations._id,
        direct: this.newPushnotification.direct,
      };

      console.log(payload);
      this.postEventNotification(payload);
    },

    postEventNotification(payload) {
      if (!payload.title || !payload.message) return;
      this.$store.commit("updating", true);
      var message = "Pushnachricht wurde gespeichert";
      if (this.editedItem.direct) {
        message = "Pushnachricht wurde gespeichert & gesendet";
      }
      return new Promise((resolve, reject) => {
        axiosAuth
          .post("eventNotifications/", payload)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getEventNotifications");
            this.newNewsModal = false;
            this.SnackbarShow("Success", "Aktion erfolgreich!", message);
            this.dialogPushnotification = false;
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            console.log(error.response);
            reject(error.response.data);
            this.SnackbarShow("Danger", "Fehler!");
          });
      });
    },
    
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return "Pushnachricht";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>

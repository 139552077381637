<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" lg="8">
    <v-container fluid>
      <h2
        class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
        style="font-size: 35px"
      >
        Neuigkeiten
      </h2>
      <v-row class="mb-6 mt-15" justify="space-around" v-if="eventNews.length > 0">
        <v-col
          v-for="(item, i) in eventNews"
          :key="item.title + i"
          lg="4"
          md="6"
          sm="12"
          cols="12"
          class="pt-0 mb-0"
        >
        <news-card :item="item"></news-card>
        </v-col>
      </v-row>
       <v-row justify="center" v-else>
          <div class="mb-10">
            <p class="text-body text-center font-weight-light mb-10 mt-5">
              Aktuell sind keine News verfügbar, schaue später wieder
              vorbei!
            </p>
            <v-row justify="center">
              <v-card>
                <v-img
                  height="100%"
                  width="200px"
                  :src="voidImage"
                  class="img-fluid border-radius-lg"
                  :alt="voidImage"
                />
              </v-card>
            </v-row>
          </div>
        </v-row>
  
    </v-container>
    <sponsors></sponsors>

    <faq></faq>
    </v-col>
  </v-row>
</template>
<script>
import NewsCard from '@/components/Widgets/NewsCard.vue'
import Sponsors from '@/components/Widgets/Sponsors.vue'
import faq from '@/components/Widgets/FAQ.vue'
export default {
  name: "Home",
  components: {
    NewsCard,
    Sponsors,
    faq
  },
  computed: {
    events() {
      return this.$store.getters.events;
    },
    eventNews() {
      return this.$store.getters.eventNews;
    },
  },
  mounted() {
    this.$store.dispatch("getEvents");
    this.$store.dispatch("getEventNews");
    window.scrollTo(0, 0);
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
  data() {
    return {
      tab: "",
      voidImage: require("@/assets/img/undraw/undraw_void_3ggu.png"),
    };
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.updating)?_c('loading-overlay',{attrs:{"text":''}}):_vm._e(),_c('v-container',{staticClass:"px-6 py-6",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"\n            font-weight-bold\n            text-uppercase\n            btn-default\n            bg-gradient-primary\n            py-2\n            px-6\n            me-2\n            mb-sm-0 mb-2\n          ",attrs:{"elevation":"0","height":"43","color":"#5e72e4","small":""},on:{"click":function($event){_vm.$router.push('/admin/events/new').catch(function () {})}}},[_vm._v("Neues Event anlegen")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mb-6 mt-10"},_vm._l((_vm.events),function(item,i){return _c('v-col',{key:item.title + i,staticClass:"pt-0 mb-10",attrs:{"lg":"4"}},[_c('v-card',{staticClass:"card card-shadow border-radius-xl py-5 text-center",attrs:{"data-animation":"true"}},[_c('div',{staticClass:"mt-n11 mx-4 card-header position-relative z-index-2"},[_c('div',{staticClass:"d-block blur-shadow-image"},[_c('img',{staticClass:"img-fluid shadow border-radius-lg",attrs:{"src":_vm.getImage(item.img),"alt":item.image}})]),_c('div',{staticClass:"colored-shadow",style:({
                    backgroundImage: 'url(' + _vm.getImage(item.img) + ')',
                  })})]),_c('div',{staticClass:"d-flex mx-auto mt-n8"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"material-icons-round text-primary ms-auto px-5",attrs:{"size":"18"},on:{"click":function($event){_vm.$router
                          .push('/admin/events/details/' + item._id)
                          .catch(function () {})}}},'v-icon',attrs,false),on),[_vm._v(" info ")])]}}],null,true)},[_c('span',[_vm._v("Details")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"material-icons-round text-info me-auto px-5",attrs:{"size":"18"},on:{"click":function($event){_vm.$router
                          .push('/admin/events/edit/' + item._id)
                          .catch(function () {})}}},'v-icon',attrs,false),on),[_vm._v(" edit ")])]}}],null,true)},[_c('span',[_vm._v("Bearbeiten")])])],1),_c('h5',{staticClass:"font-weight-normal text-typo text-h5 mt-7 mb-2 px-4"},[_c('a',{staticClass:"text-decoration-none text-default",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(item.title))])]),_c('p',{staticClass:"mb-0 text-body font-weight-light px-5"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('hr',{staticClass:"horizontal dark my-6"}),_c('div',{staticClass:"d-flex text-body mx-2 px-4"},[_c('p',{staticClass:"mb-0 font-weight-normal text-body"},[_vm._v("Anmeldungen:")]),_c('i',{staticClass:"\n                    material-icons-round\n                    position-relative\n                    ms-auto\n                    text-lg\n                    me-1\n                    my-auto\n                  "},[_vm._v("person")]),_c('p',{staticClass:"text-sm my-auto font-weight-light"},[_vm._v(" "+_vm._s(item.entries.length)+" ")])]),_c('div',{staticClass:"d-flex mb-4 mx-2 px-4 mt-4"},[_c('p',{staticClass:"mb-0 text-body font-weight-light"},[_vm._v(_vm._s(item.active ? 'Event aktiv' : 'Event inaktiv'))]),_c('v-switch',{staticClass:"d-inline-flex mt-0 pt-0 switch ms-auto",attrs:{"disabled":"","ripple":false,"hide-details":""},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1),_c('div',{staticClass:"d-flex mb-4 mx-2 px-4 mt-4"},[_c('p',{staticClass:"mb-0 text-body font-weight-light"},[_vm._v(_vm._s(item.entries_active ? 'Anmeldung aktiv' : 'Anmeldung inaktiv'))]),_c('v-switch',{staticClass:"d-inline-flex mt-0 pt-0 switch ms-auto",attrs:{"disabled":"","ripple":false,"hide-details":""},model:{value:(item.entries_active),callback:function ($$v) {_vm.$set(item, "entries_active", $$v)},expression:"item.entries_active"}})],1)])],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
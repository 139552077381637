<template>
  <div>
     <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-btn
            elevation="0"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mb-sm-0 mb-2
            "
            color="#5e72e4"
            small
            @click="$router.push('/admin/events/new').catch(() => {})"
            >Neues Event anlegen</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-row class="mb-6 mt-10">
            <v-col
              v-for="(item, i) in events"
              :key="item.title + i"
              lg="4"
              class="pt-0 mb-10"
            >
              <v-card
                class="card card-shadow border-radius-xl py-5 text-center"
                data-animation="true"
              >
                <div
                  class="mt-n11 mx-4 card-header position-relative z-index-2"
                >
                  <div class="d-block blur-shadow-image">
                    <img
                      :src="getImage(item.img)"
                      class="img-fluid shadow border-radius-lg"
                      :alt="item.image"
                    />
                  </div>
                  <div
                    class="colored-shadow"
                    v-bind:style="{
                      backgroundImage: 'url(' + getImage(item.img) + ')',
                    }"
                  ></div>
                </div>
                <div class="d-flex mx-auto mt-n8">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          $router
                            .push('/admin/events/details/' + item._id)
                            .catch(() => {})
                        "
                        class="material-icons-round text-primary ms-auto px-5"
                        size="18"
                      >
                        info
                      </v-icon>
                    </template>
                    <span>Details</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="
                          $router
                            .push('/admin/events/edit/' + item._id)
                            .catch(() => {})
                        "
                        v-bind="attrs"
                        v-on="on"
                        class="material-icons-round text-info me-auto px-5"
                        size="18"
                      >
                        edit
                      </v-icon>
                    </template>
                    <span>Bearbeiten</span>
                  </v-tooltip>
                </div>
                <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
                  <a
                    href="javascript:;"
                    class="text-decoration-none text-default"
                    >{{ item.title }}</a
                  >
                </h5>
                <p class="mb-0 text-body font-weight-light px-5">
                  {{ item.text }}
                </p>
                <hr class="horizontal dark my-6" />
                <div class="d-flex text-body mx-2 px-4">
                  <p class="mb-0 font-weight-normal text-body">Anmeldungen:</p>
                  <i
                    class="
                      material-icons-round
                      position-relative
                      ms-auto
                      text-lg
                      me-1
                      my-auto
                    "
                    >person</i
                  >
                  <p class="text-sm my-auto font-weight-light">
                    {{ item.entries.length }}
                  </p>
                </div>
                <div class="d-flex mb-4 mx-2 px-4 mt-4">
              <p class="mb-0 text-body font-weight-light">{{ item.active ? 'Event aktiv' : 'Event inaktiv' }}</p>
              <v-switch
                disabled
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                v-model="item.active"
                hide-details
              ></v-switch>
            </div>
            <div class="d-flex mb-4 mx-2 px-4 mt-4">
              <p class="mb-0 text-body font-weight-light">{{ item.entries_active ? 'Anmeldung aktiv' : 'Anmeldung inaktiv' }}</p>
              <v-switch
                disabled
                :ripple="false"
                class="d-inline-flex mt-0 pt-0 switch ms-auto"
                v-model="item.entries_active"
                hide-details
              ></v-switch>
            </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import NewProduct from "./Widgets/NewProduct.vue";
import coreData from "@/mixins/coreDataMixin";
export default {
  name: "Events",
  mixins: [coreData],
  components: {
    NewProduct,
  },
  data: function () {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

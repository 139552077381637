<template>
  <v-row
    class="
      justify-center
      d-flex
      mx-0
      mt-n16
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-container fluid class="py-6">
      <loading-overlay v-if="updating" :text="''" />
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6" class="position-relative">
      <v-row>
        <v-col cols="auto">
          <v-avatar
            style="cursor: pointer"
            width="74"
            height="74"
            class="shadow border-radius-lg"
            @click="uploadImageDialog = true"
          >
            <img
              v-if="userInformations.avatar"
              :src="getImage(userInformations.avatar)"
              alt="Avatar"
              class="border-radius-lg"
            />
            <div v-else>
              {{ userInformations.firstname.charAt(0)
              }}{{ userInformations.lastname.charAt(0) }}
            </div>
          </v-avatar>
        </v-col>
        <v-col cols="auto" class="my-auto">
          <div class="h-100 pa-3">
            <h5 class="mb-1 text-h5 text-typo font-weight-bold">
              {{ userInformations.firstname }}
              {{ userInformations.lastname }}
            </h5>
            <p class="mb-0 font-weight-light text-body text-sm">
              {{ userInformations.companyName }}
            </p>
          </div>
        </v-col>
      </v-row>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6" class="position-relative">
          <v-card class="mb-30">
            <div class="card-header-padding card-border-bottom">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    Profil bearbeiten
                  </p>
                </v-col>
              </v-row>
            </div>
            <v-card-text class="pa-0">
              <v-form>
                <h6
                  class="
                    text-caption text-uppercase
                    font-weight-600
                    text-muted
                    mb-8
                  "
                >
                  Nutzerinformation
                </h6>
                <div class="ps-lg-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Nutzername</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        v-model="userInformations.username"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >E-Mail-Adresse</label
                      >
                      <v-text-field
                        type="email"
                        hide-details
                        disabled
                        outlined
                        v-model="userInformations.email"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Vorname</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          mb-5
                        "
                        v-model="userInformations.firstname"
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Nachname</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                          mb-5
                        "
                        v-model="userInformations.lastname"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-form>

              <div class="border-bottom my-5"></div>

              <h6
                class="
                  text-caption text-uppercase
                  font-weight-600
                  text-muted
                  mb-8
                  mt-8
                "
              >
                Kontaktinformationen
              </h6>

              <div class="ps-lg-5">
                <v-row>
                  <v-col cols="12">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Straße</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="userInformations.street"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Postleitzahl</label
                    >
                    <v-text-field
                      type="number"
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="userInformations.zipcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Ort</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="userInformations.city"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" lg="4">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Land</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      placeholder="Deutschland"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>

              <div class="border-bottom my-5"></div>



              <div class="border-bottom mb-10"></div>

              <v-row class="">
                <v-col>
                  <v-row align="center" justify="space-around">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="updateUserData()"
                      class="
                        font-weight-600
                        text-capitalize
                        btn-neutral
                        py-3
                        px-6
                        rounded-sm
                        mb-5
                      "
                      color="#fff"
                      >Profil speichern</v-btn
                    >
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetUserData()"
                      class="
                        font-weight-600
                        text-capitalize
                        btn-neutral
                        py-3
                        px-6
                        rounded-sm
                        mb-5
                      "
                      color="#fff"
                      >Änderungen zurücksetzen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <hr class="vertical dark" />
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
    <v-dialog
      v-model="uploadImageDialog"
      max-width="500px"
      v-if="userInformations"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h5 text-typo font-weight-normal justify-center"
          >Neues Profilbild hochladen</v-card-title
        >
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6" class="my-5">
            <dropzone :key="uploadImageDialog" @newFile="setFile"></dropzone>
          </v-col>
        </v-row>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="uploadImageDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-outline-secondary
              bg-transparent
              py-3
              px-6
            "
            >Abbrechen</v-btn
          >

          <v-btn
            @click="uploadAvatar"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Hochladen</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import coreData from "@/mixins/coreDataMixin";
// import Dropzone from "@/views/Components/Dropzone.vue";
import axiosAuth from "@/shared/configs/axios-auth";
import Dropzone from "@/views/Components/Dropzone.vue";
export default {
  name: "Profile-Overview",
  mixins: [coreData],
  components: {
    // LoadingOverlay,
    Dropzone,
  },
  data: function () {
    return {
      uploadImageDialog: false,
    };
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  mounted() {
    this.$store.dispatch("tryAutoLogin");
  },
  methods: {
    updateUserData() {
      this.$store.dispatch("updateUserInformations", this.userInformations);
      this.SnackbarShow(
        "Success",
        "Aktion erfolgreich!",
        "Profil gespeichert!"
      );
    },
    resetUserData() {
      this.$store.dispatch("userInformations");
    },
    setFile(file) {
      this.userInformations.file = file;
    },
    uploadAvatar() {
      if (!this.userInformations.file) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("image", this.userInformations.file);
        axiosAuth
          .patch("/authbadge/me/", formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("userInformations");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Profil gespeichert!"
            );
            this.uploadImageDialog = false;
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
};
</script>

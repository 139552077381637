<template>
  <v-container fluid class="py-6">
    <v-row>
      <v-col lg="12">
        <v-row>
          <v-col md="6" cols="12" v-for="card in cards" :key="card.title">
            <v-card class="mb-3 card-shadow border-radius-xl py-4">
              <v-row no-gutters class="px-4">
                <v-col sm="4">
                  <v-avatar
                    color="bg-gradient-info shadow border-radius-xl mt-n8"
                    class="shadow-dark"
                    height="64"
                    width="64"
                  >
                    <v-icon class="material-icons-round text-white" size="24">{{
                      card.icon
                    }}</v-icon>
                  </v-avatar>
                </v-col>
                <v-col sm="8" class="text-end">
                  <p
                    class="
                      text-sm
                      mb-0
                      text-capitalize text-body
                      font-weight-light
                    "
                  >
                    {{ card.text }}
                  </p>
                  <h4 class="text-h4 text-typo font-weight-bolder mb-0">
                    {{ card.value }}
                  </h4>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12">
        <reports-table></reports-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import ReportsTable from "./Widgets/ReportsTable.vue";

export default {
  name: "Reports",
  components: {
    ReportsTable,
  },
  data: function () {
    return {};
  },
  mounted() {
    this.$store.dispatch("getReports");
  },
  computed: {
    reports() {
      return this.$store.getters.reports;
    },
    cards() {
      return [
        {
          icon: "pan_tool",
          text: "Reports offen",
          value: this.reports.filter((element) => element.status == "offen")
            .length,
          percent: "+124%",
        },
        {
          icon: "pan_tool",
          text: "Reports gesamt",
          value: this.reports.length,
          percent: "+124%",
        },

      ];
    },
  },
};
</script>

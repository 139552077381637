<template>
  <v-card
    :loading="loading"
    class="mx-auto my-12 shadow"
    max-width="600"
    :href="'/events/' + (item.url ? item.url : item._id)"
    @click="
      $router
        .push('/events/' + (item.url ? item.url : item._id))
        .catch(() => {})
    "
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="250" :src="getImage(item.img)"></v-img>

    <v-card-title style="height: 100px; overflow: hidden">{{
      item.title
    }}</v-card-title>

    <v-card-text>
      <div class="grey--text">
        {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
      </div>
      <div class="mt-4 text-subtitle-1">
        {{ item.city }} • {{ item.price > 0 ? 'Online: ' + item.price + "€" : "Gratis" }}{{ item.price_lowered > 0 ? ' • Erm.: ' + item.price_lowered + "€" : "" }} {{ item.price_local > 0 ? ' • Abendk.: ' + item.price_local + "€" : "" }}
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-title>Verfügbare Karten:</v-card-title>

    <v-card-text>
      <!-- <v-chip-group active-class="deep-purple accent-4 white--text" column> -->
        <v-chip v-if="bookable">{{ calcSlots }} / {{ item.slots }}</v-chip>
        <v-chip color="red" v-else>ausgebucht!</v-chip>
      <!-- </v-chip-group> -->
    </v-card-text>

    <!-- <v-card-actions>
      <v-btn color="success lighten-2" text> Jetzt Bestellen </v-btn>
    </v-card-actions> -->
  </v-card>
  <!-- <v-card
            class="card card-shadow border-radius-xl py-5 text-center"
            href="/events/details/' + item._id"
            @click="$router.push('/events/details/' + item._id).catch(() => {})"
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <img
                  position="top center"
                  fill
                  height="250px"
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>
            <div style="height: 90px" class="px-5">
              <div class="mt-2">
                <h4
                  class="
                    px-1
                    h4
                    my-auto
                    text-typo text-start
                    font-weight-normal
                  "
                >
                  {{ item.city }}
                </h4>
              </div>
              <v-row align="center" justify="start" class="h-100">
                <div>
                  <h5
                    class="font-weight-normal text-start text-typo text-h5 px-4"
                  >
                    {{ item.title }}
                  </h5>
                </div>
              </v-row>
            </div>
            <div>
              <p
                class="mx-5 text-start font-weight-normal text-typo"
                v-html="
                  item.description.length > 200
                    ? item.description.substr(0, 200) + ' ...'
                    : item.description
                "
              ></p>
            </div>
            <hr class="horizontal dark mb-2" />
            <div class="text-body mx-2 px-4">
              <v-row justify="space-between" class="ma-0">
                <p class="mb-0 font-weight-normal text-body">
                  Verfügbare Plätze:
                </p>
                <p class="my-auto font-weight-normal">
                  {{ item.slots - item.entries.length }} / {{ item.slots }}
                </p>
              </v-row>
              <div class="mt-2">
                <h3 class="h3 my-auto text-start font-weight-normal">
                  {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
                </h3>
              </div>
            </div>
          </v-card> -->
</template>

<script>
export default {
  name: "EventCard",
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    calcSlots() {
      try {
        return this.item.slots - this.item.entries.length <= 0
          ? 0
          : this.item.slots - this.item.entries.length;
      } catch (error) {
        return 0;
      }
    },
    bookable() {
      if (this.calcSlots > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>
<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <!-- <v-col cols="12" class="mx-auto text-center mb-6">
      <v-tabs v-model="tab" hide-slider class="border-radius-lg mt-8">
        <v-tabs-slider>asd</v-tabs-slider>
        <v-tab :ripple="false" class="ms-auto" href="#tab-1"> Events </v-tab>
        <v-tab :ripple="false" class="me-auto" href="#tab-2"> News </v-tab>
      </v-tabs>
    </v-col> -->
    <v-col cols="12" md="8">
      <v-container fluid>
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
        >
          Veranstaltungen
        </h2>
        <v-row
          class="mb-6 mt-15"
          justify="space-around"
          v-if="events.length > 0"
        >
          <v-col
            v-for="(item, i) in events"
            :key="item.title + i"
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="pt-0 mb-10"
          >
            <event-card :item="item"></event-card>
          </v-col>
        </v-row>
        <v-row justify="center" v-else>
          <div class="mb-10">
            <p class="text-body text-center font-weight-light mb-10 mt-5">
              Aktuell sind keine Veranstaltungen verfügbar, schaue später wieder
              vorbei!
            </p>
            <v-row justify="center">
              <v-card>
                <v-img
                  height="100%"
                  width="200px"
                  :src="voidImage"
                  class="img-fluid border-radius-lg"
                  :alt="voidImage"
                />
              </v-card>
            </v-row>
          </div>
        </v-row>
        <!-- <h2
        class="mx-3 h1 font-weight-normal text-center mb-10 text-body"
        style="font-size: 35px"
        v-if="eventNews.length > 0"
      >
        Aktuelle News
      </h2>
      <v-row class="mb-6 mt-15" justify="space-around">
        <v-col
          v-for="(item, i) in eventNews.slice(0, 3)"
          :key="item.title + i"
          lg="4"
          class="pt-0 mb-10"
        >
          <v-card
            class="card-shadow border-radius-xl py-5 text-center"
            :href="'/news/' + item.url"
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  position="top center"
                  fill
                  height="250px"
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>
            <div style="height: 80px" class="px-5 mt-3">
              <v-row align="center" justify="center" class="h-100">
                <div>
                  <h5 class="font-weight-normal text-typo text-h5 px-4">
                    {{ item.title }}
                  </h5>
                </div>
              </v-row>
            </div>
            <div>
              <p
                class="mx-5 text-start text-typo"
                v-html="
                  item.description.length > 200
                    ? item.description.substr(0, 200) + ' ... weiter lesen.'
                    : item.description
                "
              ></p>
            </div>
            <hr class="horizontal dark mb-6" />
            <div class="">
              <p class="my-auto text-body font-weight-light">
                {{ item.manager.username }}
              </p>
            </div>
            <div class="">
              <p class="my-auto text-body font-weight-light">
                {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
              </p>
            </div>
          </v-card>
        </v-col>
      </v-row> -->
      </v-container>
      <sponsors></sponsors>

      <faq></faq>
    </v-col>
  </v-row>
</template>
<script>
import EventCard from "@/components/Widgets/EventCard.vue";
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
export default {
  name: "Home",
  components: {
    EventCard,
    Sponsors,
    faq,
  },
  computed: {
    events() {
      return this.$store.getters.events;
    },
    eventNews() {
      return this.$store.getters.eventNews;
    },
  },
  mounted() {
    this.$store.dispatch("getEvents");
    this.$store.dispatch("getEventNews");
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
  data() {
    return {
      tab: "",
      voidImage: require("@/assets/img/undraw/undraw_void_3ggu.png"),
    };
  },
};
</script>


<template>
  <v-row
    class="
      justify-center
      d-flex
      mx-0
      mt-n16
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" lg="8">
    <v-container fluid class="py-6">
      <loading-overlay v-if="updating" :text="''" />
      <v-row>
        <v-col cols="auto">
          <v-avatar
            style="cursor: pointer"
            width="74"
            height="74"
            class="shadow border-radius-lg"
            @click="uploadImageDialog = true"
          >
            <img
              v-if="userInformations.avatar"
              :src="getImage(userInformations.avatar)"
              alt="Avatar"
              class="border-radius-lg"
            />
            <div v-else>
              {{ userInformations.firstname.charAt(0)
              }}{{ userInformations.lastname.charAt(0) }}
            </div>
          </v-avatar>
        </v-col>
        <v-col cols="auto" class="my-auto">
          <div class="h-100 pa-3">
            <h5 class="mb-1 text-h5 text-typo font-weight-bold">
              {{ userInformations.firstname }}
              {{ userInformations.lastname }}
            </h5>
            <p class="mb-0 font-weight-light text-body text-sm">
              {{ userInformations.companyName }}
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="position-relative">
          <data-table-invoices></data-table-invoices>
        </v-col>
      </v-row>

      
    </v-container>
    </v-col>
  </v-row>
</template>
<script>
import coreData from "@/mixins/coreDataMixin";
// import Dropzone from "@/views/Components/Dropzone.vue";
import axiosAuth from "@/shared/configs/axios-auth";
import Dropzone from "@/views/Components/Dropzone.vue";
import DataTableInvoices from "./Widgets/DatatableInvoices.vue"
export default {
  name: "Profile-Overview",
  mixins: [coreData],
  components: {
    DataTableInvoices,
    Dropzone,
  },
  data: function () {
    return {
      uploadImageDialog: false,
    };
  },
  computed: {
    userInformations() {
      return this.$store.getters.userInformations;
    },
  },
  mounted() {
    this.$store.dispatch("tryAutoLogin");
  },
  methods: {
    updateUserData() {
      this.$store.dispatch("updateUserInformations", this.userInformations);
      this.SnackbarShow(
        "Success",
        "Aktion erfolgreich!",
        "Profil gespeichert!"
      );
    },
    resetUserData() {
      this.$store.dispatch("userInformations");
    },
    setFile(file) {
      this.userInformations.file = file;
    },
    uploadAvatar() {
      if (!this.userInformations.file) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("image", this.userInformations.file);
        axiosAuth
          .patch("/authbadge/me/", formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("userInformations");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Profil gespeichert!"
            );
            this.uploadImageDialog = false;
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
};
</script>

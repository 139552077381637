<template>
  <v-container>
    <v-col cols="8" class="mx-auto text-center mt-10">
      <h2
        class="mx-3 h1 font-weight-normal text-center text-body"
        style="font-size: 35px"
      >
        Sponsoren und Unterstützer
      </h2>
      <p class="text-body font-weight-light">
        Sie machen es möglich!
      </p>
    </v-col>
    <v-col cols="12">
      <v-row class="mt-1 mx-7" align="center" justify="center">
        <v-col
          lg="2"
          sm="3"
          cols="6"
          class="mb-6"
          v-for="brand in brands"
          :key="brand.name"
        >
          <v-card :href="brand.link" target="_blank">
            <v-img
              contain
              :alt="brand.name"
              :src="brand.image"
              height="100px"
              width="100px"
              class="opacity-9"
            ></v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
export default {
  name: "Sponsoren",
  props: {
  },
  data() {
    return {
      brands: [
        {
          name: "Oelsnitz Media",
          image: require("@/assets/img/logos/sponsors/Oelsnitz-media.jpg"),
          link: "https://www.oelsnitz.media/",
        },
        {
          name: "Gewerbeverbank Oelsnitz/Vogtl. e.V.",
          image: require("@/assets/img/logos/sponsors/gvov.png"),
          link: "https://gvov.oelsnitz-vogtland-info.de/",
        },
        {
          name: "Oelsnitzer Kultur GmbH",
          image: require("@/assets/img/logos/sponsors/okg-logo.png"),
          link: "https://www.oelsnitz.de/",
        },
        {
          name: "Sparkasse Vogtland",
          image: require("@/assets/img/logos/sponsors/spk-logo-desktop.png"),
          link: "https://www.sparkasse-vogtland.de/",
        },
        {
          name: "eatDorado",
          image: require("@/assets/img/logos/sponsors/eatDoradoLogo.png"),
          link: "https://www.eatdorado.de/",
        },
        {
          name: "Kathrins",
          image: require("@/assets/img/logos/sponsors/kathrins.gif"),
          link: "https://www.facebook.com/Kathrins.Delicious.Food/",
        },
        {
          name: "Stadt Oelsnitz/Vogtl.",
          image: require("@/assets/img/logos/sponsors/oelsnitz.jpeg"),
          link: "https://www.oelsnitz.de/",
        },
        {
          name: "Meiser",
          image: require("@/assets/img/logos/sponsors/meiser-logo.png"),
          link: "https://www.meiser.de/de/",
        },
        {
          name: "Optiplan",
          image: require("@/assets/img/logos/sponsors/optiplan.png"),
          link: "https://optiplan.eu/de/index.html",
        },
        {
          name: "Sternquell",
          image: require("@/assets/img/logos/sponsors/sternquell.png"),
          link: "https://www.sternquell.de/",
        },
        {
          name: "Morgner",
          image: require("@/assets/img/logos/sponsors/morgner.jpeg"),
          link: "https://www.morgner-heizung.de/",
        },
      ],
    };
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>
<template>
  <div>
    <div class="d-sm-flex justify-end mb-5">
      <div class="mr-2">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="$store.dispatch('getReports')"
              v-bind="attrs"
              v-on="on"
              class="material-icons-round text-primary ms-auto"
              size="30"
            >
              refresh
            </v-icon>
          </template>
          <span>Aktualisieren</span>
        </v-tooltip>
      </div>
      <!-- <v-btn
        v-if="filter != ''"
        elevation="0"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          shadow-none
          btn-default btn-outline-default
          py-2
          px-6
          me-2
          mb-sm-0 mb-2
        "
        color="transparent"
        small
        >Filter: {{ filter }}</v-btn
      >
      <div class="d-flex ms-auto">
        <v-menu transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default btn-outline-default
                shadow-none
                py-2
                px-6
                me-2
              "
              color="transparent"
              small
            >
              Filters
              <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <v-list-item
              class="list-item-hover-active py-2"
              @click="filter = 'active'"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  Status: Aktiv
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              class="list-item-hover-active pb-2"
              @click="filter = 'inactive'"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                >
                  Status: Inaktiv
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <hr class="horizontal dark" />
            <v-list-item
              class="list-item-hover-active py-2"
              @click="filter = ''"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-danger ls-0 font-weight-600 mb-0"
                >
                  Filter entfernen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div> -->
    </div>
    <v-card class="card-shadow border-radius-xl">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="reports"
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Suche"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size="16"
                        class="material-icons-round mt-n2"
                        >search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="600px">
                <v-card class="card-shadow border-radius-xl" v-if="editedItem">
                  <div class="card-header-padding card-border-bottom">
                    <v-row class="justify-space-between">
                      <span class="font-weight-bold text-h5 text-typo mb-0"
                        >Meldungsstatus: {{ editedItem.status }}
                      </span>
                      <span class="font-weight-bold text-h5 text-typo mb-0"
                        >Datum:
                        {{ editedItem.created | moment("DD.MM.YYYY HH:mm") }}
                        Uhr</span
                      >
                    </v-row>
                  </div>
                  <v-card-text class="card-padding">
                    <v-card elevation="3" style="position: relative" class="">
                      <div class="pa-5">
                        <span class="font-weight-bold text-h5 text-typo mb-0"
                          >Typ: {{ getReportType(editedItem) }}</span
                        >
                      </div>
                    </v-card>
                    <v-card
                      elevation="3"
                      style="position: relative"
                      class="mt-5"
                      v-if="
                        getReportType(editedItem) == 'Kommentar' &&
                        editedItem.commentId
                      "
                      ><div class="pa-5">
                        {{ editedItem.commentId.comment }}
                      </div></v-card
                    >
                    <v-card
                      elevation="3"
                      style="position: relative"
                      class=""
                      v-if="
                        getReportType(editedItem) == 'Feed' && editedItem.feedId
                      "
                    >
                      <v-card
                        elevation="3"
                        style="position: relative"
                        class="mt-5"
                        v-if="
                          editedItem.feedId.images || editedItem.feedId.note
                        "
                      >
                        <v-img
                          v-if="
                            editedItem.feedId.images &&
                            editedItem.feedId.images.length > 0
                          "
                          height="350"
                          :src="getImage(editedItem.feedId.images[0])"
                        ></v-img>

                        <div
                          class="pt-5 mt-2 pa-5"
                          v-if="editedItem.feedId.note"
                        >
                          <div class="font-weight-bold text-h4">
                            {{ editedItem.feedId.note }}
                          </div>
                        </div>
                      </v-card>
                    </v-card>
                    <v-card
                      elevation="3"
                      style="position: relative"
                      class=""
                      v-if="
                        getReportType(editedItem) == 'Quest' &&
                        editedItem.questId
                      "
                    >
                      <v-img
                        v-if="editedItem.images && editedItem.images.length > 0"
                        height="350"
                        :src="getImage(editedItem.images[0])"
                      ></v-img>

                      <div class="pt-5 mt-2 pa-5">
                        <GmapMap
                          v-if="editedItem.questId.coordinates"
                          :center="{
                            lat: editedItem.questId.coordinates.lat,
                            lng: editedItem.questId.coordinates.lon,
                          }"
                          :zoom="12"
                          map-type-id="terrain"
                          style="width: 500px; height: 300px"
                        >
                          <GmapMarker
                            :position="{
                              lat: editedItem.questId.coordinates.lat,
                              lng: editedItem.questId.coordinates.lon,
                            }"
                            :clickable="true"
                            :draggable="true"
                            @click="center = m.position"
                          />
                        </GmapMap>
                        <div class="font-weight-bold text-h4 mt-5">
                          {{ editedItem.questId.questName }}
                        </div>
                        <div class="font-weight-light text-h6">
                          {{ editedItem.questId.subTitle }}
                        </div>
                      </div>
                    </v-card>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end" v-if="
                        getReportType(editedItem) == 'Quest' &&
                        editedItem.questId
                      ">
                    <v-btn
                      @click="rejectReport(editedItem)"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-warning
                        bg-gradient-warning
                        py-3
                        px-6
                      "
                      >Meldung löschen</v-btn
                    >
                    <v-btn
                      v-if="!editedItem.questId.deleted"
                      @click="deleteQuest"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-danger
                        bg-gradient-danger
                        py-3
                        px-6
                      "
                      >{{ getReportType(editedItem) }} sperren</v-btn
                    >

                    <v-btn
                    v-else
                      
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >Quest gesperrt</v-btn
                    >
                    
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                  </v-card-actions>

                  <v-card-actions class="card-padding d-flex justify-end" v-if="
                        getReportType(editedItem) == 'Feed' &&
                        editedItem.feedId
                      ">
                    <v-btn
                      @click="rejectReport(editedItem)"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-warning
                        bg-gradient-warning
                        py-3
                        px-6
                      "
                      >Meldung löschen</v-btn
                    >
                    <v-btn
                      v-if="!editedItem.feedId.deleted"
                      @click="deleteFeed"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-danger
                        bg-gradient-danger
                        py-3
                        px-6
                      "
                      >{{ getReportType(editedItem) }} sperren</v-btn
                    >

                    <v-btn
                    v-else
                      
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >Feed gesperrt</v-btn
                    >
                    
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions class="card-padding d-flex justify-end" v-if="
                        getReportType(editedItem) == 'Kommentar' &&
                        editedItem.commentId
                      ">
                    <v-btn
                      @click="rejectReport(editedItem)"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-warning
                        bg-gradient-warning
                        py-3
                        px-6
                      "
                      >Meldung löschen</v-btn
                    >
                    <v-btn
                      
                      @click="deleteComment"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-danger
                        bg-gradient-danger
                        py-3
                        px-6
                      "
                      >{{ getReportType(editedItem) }} löschen</v-btn
                    >
                    
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions class="card-padding d-flex justify-end" v-if="
                        getReportType(editedItem) == 'invalid' || getReportType(editedItem) == 'Nutzer' 
                      ">
                    <v-btn
                      @click="rejectReport(editedItem)"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-warning
                        bg-gradient-warning
                        py-3
                        px-6
                      "
                      >Meldung löschen</v-btn
                    >
                    
                    
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="showParticipantsDialog" max-width="600px">
                <v-card
                  class="card-shadow card-padding border-radius-xl"
                  v-if="!editedItem.participant_data"
                >
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0"
                      >Grund der Suspendierung</span
                    >
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.firstname"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Vorname"
                            label="Vorname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.lastname"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Nachname"
                            label="Nachname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.email"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="E-Mail-Adresse"
                            label="E-Mail-Adresse"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.street"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Straße"
                            label="Straße"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.zip"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Postleitzahl"
                            label="Postleitzahl"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.city"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Ort"
                            label="Ort"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                          <v-btn
                            @click="
                              emailResended ? null : resendEmail(editedItem)
                            "
                            elevation="0"
                            :ripple="false"
                            height="43"
                            class="
                              font-weight-normal
                              text-capitalize
                              btn-ls btn-primary
                              py-3
                              px-6
                            "
                            :class="
                              emailResended == editedItem._id
                                ? 'bg-gradient-success'
                                : 'bg-gradient-info'
                            "
                            >{{
                              emailResended == editedItem._id
                                ? "Ticket-E-Mail gesendet!"
                                : "Ticket-E-Mail erneut senden"
                            }}</v-btn
                          >
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <v-card class="card-shadow border-radius-xl" v-else>
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0"
                      >Angemeldeter Teilnehmer</span
                    >
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.participant_data.name"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Name"
                            label="Name"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.participant_data.street"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Straße"
                            label="Straße"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.participant_data.zipcode"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Postleitzahl"
                            label="Postleitzahl"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.participant_data.city"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Ort"
                            label="Ort"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.creator.fullname="{ item }">
            <v-list class="py-0">
              <v-list-item class="px-0 py-1">
                <v-list-item-avatar rounded :size="36" class="my-0 me-4">
                  <v-img
                    v-if="item.creator.avatar"
                    :alt="item.avatar"
                    :src="getImage(item.creator.avatar)"
                    class="rounded-circle"
                  ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    class="mb-0 text-sm text-typo font-weight-normal"
                    >{{ item.creator.fullname }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </template>

          <template v-slot:item.email="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.email }}
            </span>
          </template>

          <template v-slot:item.deleted="{ item }">
            <div class="d-flex align-center" style="max-width: 50px">
              <v-btn
                @click="updateRedeemed(item)"
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2"
                :class="!item.deleted ? 'border-success' : 'border-danger'"
              >
                <v-icon
                  size="8"
                  :class="!item.deleted ? 'text-success' : 'text-danger'"
                >
                  {{ !item.deleted ? "fas fa-check" : "fas fa-times" }}
                </v-icon>
              </v-btn>
              <!-- <span class="text-body text-xs">{{ item.deleted }}</span> -->
            </div>
          </template>

          <template v-slot:item.type="{ item }">
            <div class="d-flex align-center" style="max-width: 50px">
              {{ getReportType(item) }}
            </div>
          </template>

          <template v-slot:item.created="{ item }">
            <div class="d-flex align-center" style="max-width: 50px">
              {{ item.created | moment("DD.MM.YYYY HH:mm") }}
            </div>
          </template>

          <template v-slot:item.emailVerified="{ item }">
            <div class="d-flex align-center">
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2"
                :class="
                  item.emailVerified ? 'border-success' : 'border-default'
                "
              >
                <v-icon
                  size="8"
                  :class="item.emailVerified ? 'text-success' : 'text-dark'"
                >
                  {{ item.emailVerified ? "fas fa-check" : "fas fa-times" }}
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">{{ item.status }}</span>
            </div>
          </template>

          <template v-slot:item._id="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">search</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#D81B60"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import coreData from "@/mixins/coreDataMixin";
// import {gmapApi} from 'vue2-google-maps'

export default {
  name: "QuestsTable",
  mixins: [coreData],

  data() {
    return {
      sortBy: "status",
      sortDesc: true,
      filter: "",
      dialogNoInvoice: false,
      showParticipantsDialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      headers: [
        {
          text: "Gemeldet von",
          align: "start",
          cellClass: "border-bottom",
          sortable: true,
          value: "creator.fullname",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "status",
          value: "status",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Meldegrund",
          value: "reason",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Typ",
          value: "type",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        // {
        //   text: "Region",
        //   value: "region",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Datum",
          value: "created",
          sortable: true,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Aktionen",
          value: "_id",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  mounted() {
    // At this point, the child GmapMap has been mounted, but
    // its map has not been initialized.
    // Therefore we need to write mapRef.$mapPromise.then(() => ...)
    // this.$refs.mapRef.$mapPromise.then((map) => {
    //   map.panTo({lat: 1.38, lng: 103.80})
    // })
  },
  methods: {
    rejectReport(item) {
      console.log(item._id)
      this.$store.dispatch("deleteReport", item._id);
      this.$store.dispatch("getReports");
      this.close();
    },
    deleteQuest() {
      this.updateQuest({questId: this.editedItem.questId._id, deleted: true});
      this.$store.dispatch("getQuests");
      this.$store.dispatch("updateQuest", this.editedItem.questId);
      this.$store.dispatch("updateReport", this.editedItem._id);
      this.editedItem.questId.deleted = true;
       this.editedItem.status = 'bearbeitet';
    },
    deleteFeed() {
      this.updateFeed({feedId: this.editedItem.feedId._id, deleted: true});
      this.$store.dispatch("getFeed");
      this.$store.dispatch("updateReport", this.editedItem._id);
      this.editedItem.feedId.deleted = true;
       this.editedItem.status = 'bearbeitet';
    },
    deleteComment() {
      this.$store.dispatch("deleteFeedComments", this.editedItem.commentId._id);
      this.$store.dispatch("deleteReport", this.editedItem._id);
       this.editedItem.status = 'bearbeitet';
       this.close();
    },
    getReportType(item) {
      if (item.feedId) {
        return "Feed";
      } else if (item.commentId) {
        return "Kommentar";
      } else if (item.userId) {
        return "Nutzer";
      } else if (item.questId) {
        return "Quest";
      }

      return "invalid";
    },
    editItem(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showParticipants(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.showParticipantsDialog = true;
    },
    // deleteItemConfirm() {
    //   this.users.splice(this.editedIndex, 1);
    //   this.closeDelete();
    // },

    close() {
      this.dialog = false;
      this.showParticipantsDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    save() {
      // this.editedItem.deleted = !this.editedItem.deleted
      this.updateQuest({
        questId: this.editedItem._id,
        deleted: !this.editedItem.deleted,
      });

      this.$store.dispatch("getQuests");

      this.editedItem.deleted = !this.editedItem.deleted;

      // this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    markers() {
      return [];
    },
    // google: gmapApi,
    users() {
      return this.$store.getters.users;
    },
    quests() {
      return this.$store.getters.quests;
    },
    reports() {
      return this.$store.getters.reports;
    },
    formTitle() {
      return "Übersicht Meldung";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>

<template>
  <div>
    <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6" v-if="event">
      <v-row class="mb-5">
        <v-col cols="12">
          <v-btn
            elevation="0"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-primary
              py-2
              px-6
              me-2
              mb-sm-0 mb-2
            "
            color="#5e72e4"
            small
            @click="
              $router.push('/admin/events/details/' + event._id).catch(() => {})
            "
            >Event Details</v-btn
          >
        </v-col>
      </v-row>
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image w-100">
                <img
                  :src="getImage(event.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="event.img"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(event.img) + ')',
                }"
              ></div>
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <p class="font-weight-600 text-h3 text-typo mb-0">
                    {{ event.title }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Event Informationen
              </h5>
              <v-row>
                <v-col cols="12" lg="4" class="mt-5">
                  <h6
                    class="
                      text-caption text-uppercase
                      font-weight-600
                      text-muted
                    "
                  >
                    Neues Titelbild hochladen (optional)
                  </h6>
                  <dropzone @newFile="setFile"></dropzone>
                </v-col>
              </v-row>
              <div class="border-bottom my-5"></div>
              <v-form>
                <div class="ps-lg-5">
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventtitel</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        v-model="event.title"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>

                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventkürzel (für Codes)</label
                      >
                      <v-text-field
                        type="email"
                        hide-details
                        outlined
                        v-model="event.short"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Eventbeschreibung</label
                      >
                      <vue-editor v-model="event.description"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Wichtige Hinweise (optional)</label
                      >
                      <vue-editor v-model="event.hints"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >E-Mail-Vorlage</label
                      >
                      <vue-editor v-model="event.html"></vue-editor>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Anzahl Teilnehmer</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        type="number"
                        v-model="event.slots"
                      >
                      </v-text-field>

                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Veranstalter</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        v-model="event.organizer"
                      >
                      </v-text-field>
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 mt-5 d-block"
                        >Website Veranstalter (optional)</label
                      >
                      <v-text-field
                        hide-details
                        outlined
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                        v-model="event.website"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-row justify="center" class="mt-1 mb-3">
                        <label for="datepicker">Startdatum und Zeit</label>
                      </v-row>
                      <v-row justify="center" class="mb-4">
                        <date-picker
                          id="datepicker"
                          v-model="event.date"
                          mode="dateTime"
                          is24hr
                          :min-date="new Date()"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Veranstaltungsort
              </h5>

              <div class="ps-lg-5 mt-5">
                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Straße</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="event.street"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Postleitzahl</label
                    >
                    <v-text-field
                      type="number"
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="event.zipcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Ort</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="event.city"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Land</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      placeholder="Deutschland"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
          <!-- <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <div class="ps-lg-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Anzahl Teilnehmer</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      type="number"
                      v-model="event.slots"
                    >
                    </v-text-field>

                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 mt-5 d-block"
                      >Veranstalter</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="event.organizer"
                    >
                    </v-text-field>
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 mt-5 d-block"
                      >Website Veranstalter (optional)</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="event.website"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-row justify="center" class="mt-1 mb-3">
                      <label for="datepicker">Startdatum und Zeit</label>
                    </v-row>
                    <v-row justify="center" class="mb-4">
                      <date-picker
                        id="datepicker"
                        v-model="event.date"
                        mode="dateTime"
                        is24hr
                        :min-date="new Date()"
                      />
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card> -->
          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Rechnungsangaben
              </h5>

              <div class="ps-lg-5 mt-5">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Preis Online in € (0.00€ = Gratis)</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          v-model="event.price"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Eintrittspreis Ermäßigt in € (0.00€ = Gratis)</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          v-model="event.price_lowered"
                        >
                        </v-text-field>
                      </v-col>
                      
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Preis Abendkasse in € (0.00€ = Gratis)</label
                        >
                        <v-text-field
                          hide-details
                          outlined
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          v-model="event.price_local"
                        >
                        </v-text-field>
                      </v-col>
                      
                    </v-row>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-col cols="12">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Ermäßigungshinweise</label
                        >
                        <v-textarea
                          hide-details
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                          dense
                          flat
                          outlined
                          height="106"
                          name="input-7-4"
                          v-model="event.price_lowered_info"
                          placeholder="Ermäßigungshinweise"
                        ></v-textarea>
                      </v-col>
                      <v-col>
                        <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Rechnungs-Prefix</label
                    >
                    <v-text-field
                      hide-details
                      outlined
                      class="
                        input-style
                        font-size-input
                        text-light-input
                        placeholder-light
                      "
                      v-model="event.invoicePrefix"
                    >
                    </v-text-field>
                      </v-col>
                    </v-row>
                    
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <v-row class="">
                <v-col>
                  <v-row align="center" justify="space-around">
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="postEventTest()"
                      class="
                        font-weight-600
                        text-capitalize
                        btn-neutral
                        py-3
                        px-6
                        rounded-sm
                      "
                      color="#fff"
                      >Event speichern</v-btn
                    >
                    <v-btn
                      elevation="0"
                      :ripple="false"
                      height="43"
                      @click="resetUserData()"
                      class="
                        font-weight-600
                        text-capitalize
                        btn-neutral
                        py-3
                        px-6
                        rounded-sm
                      "
                      color="#fff"
                      >Änderungen zurücksetzen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
          <v-row class="mb-6 mt-10">
            <v-col
              v-for="(item, i) in events"
              :key="item.title + i"
              lg="4"
              class="pt-0 mb-10"
            >
              <v-card
                class="card card-shadow border-radius-xl py-5 text-center"
                @click="$router.push('/admin/events/details/' + item._id).catch(() => {})"
              >
                <div
                  class="mt-n11 mx-4 card-header position-relative z-index-2"
                >
                  <div class="d-block blur-shadow-image">
                    <img
                      :src="getImage(item.img)"
                      class="img-fluid shadow border-radius-lg"
                      :alt="item.image"
                    />
                  </div>
                  <div
                    class="colored-shadow"
                    v-bind:style="{
                      backgroundImage: 'url(' + getImage(item.img) + ')',
                    }"
                  ></div>
                </div>
                <h5 class="font-weight-normal text-typo text-h5 mt-7 mb-2 px-4">
                  <a
                    href="javascript:;"
                    class="text-decoration-none text-default"
                    >{{ item.title }}</a
                  >
                </h5>
                <p class="mb-0 text-body font-weight-light px-5">
                  {{ item.text }}
                </p>
                <hr class="horizontal dark my-6" />
                <div class="d-flex text-body mx-2 px-4">
                  <p class="mb-0 font-weight-normal text-body">Anmeldungen:</p>
                  <i
                    class="
                      material-icons-round
                      position-relative
                      ms-auto
                      text-lg
                      me-1
                      my-auto
                    "
                    >person</i
                  >
                  <p class="text-sm my-auto font-weight-light">
                    {{ item.entries.length }}
                  </p>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";

import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "EventEdit",
  mixins: [coreData],
  components: {
    VueEditor,
    DatePicker,
    Dropzone,
  },
  data: function () {
    return {};
  },
  mounted() {},
  computed: {},
  methods: {
    setFile(file) {
      console.log("file emitted!");
      this.event.file = file;
    },
    updateEvent() {
      //  event.edit = false
      this.$store.dispatch("updateEvent", this.event);
      // this.eventEditModal = false;
      this.SnackbarShow("Success");
    },

    postEventTest() {
      console.log("postevent");
      if (!this.event.title || !this.event.description) return;
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("description", this.event.description);
        formData.append("title", this.event.title);
        formData.append("disclaimer", this.event.disclaimer);
        formData.append("organizer", this.event.organizer);
        formData.append("date", new Date(this.event.date));
        formData.append("slots", this.event.slots);
        formData.append("hints", this.event.hints);
        formData.append("price", this.event.price);
        formData.append("price_lowered", this.event.price_lowered);
        formData.append("price_lowered_info", this.event.price_lowered_info);
        formData.append("price_local", this.event.price_local);
        formData.append("html", this.event.html);
        // formData.append(
        //   "sponsors",
        //   JSON.stringify(
        //     this.event.sponsors.filter((sponsor) => sponsor.title)
        //   )
        // );
        formData.append("short", this.event.short);
        formData.append("manager", this.userInformations._id);
        formData.append("client_id", this.userInformations.client_id);
        formData.append("street", this.event.street);
        formData.append("city", this.event.city);
        formData.append("zipcode", this.event.zipcode);
        formData.append("invoicePrefix", this.event.invoicePrefix);
        formData.append("website", this.event.website);

        if (this.event.file) {
          formData.append("image", this.event.file);
        } else {
          formData.append("img", this.event.img);
        }

        axiosAuth
          .patch("events/" + this.event._id, formData)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            // this.$store.dispatch("getEventsAdmin");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            console.log(error.response);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            reject(error.response.data);
          });
      });
    },
  },
};
</script>

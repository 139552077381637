<template>
  <div>
    <div class="d-sm-flex justify-between mb-5">
      <v-btn
        v-if="filter != ''"
        elevation="0"
        height="43"
        class="
          font-weight-bold
          text-uppercase
          shadow-none
          btn-default btn-outline-default
          py-2
          px-6
          me-2
          mb-sm-0 mb-2
        "
        color="transparent"
        small
        >Filter: {{ filter }}</v-btn
      >
      <div class="d-flex ms-auto">
        <v-menu transition="slide-y-transition" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              elevation="0"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default btn-outline-default
                shadow-none
                py-2
                px-6
                me-2
              "
              color="transparent"
              small
            >
              Filters
              <v-icon>ni ni-bold-down text-xs ms-1</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <v-list-item class="list-item-hover-active py-2" @click="filter = 'Eingelöst'">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  
                >
                  Status: Eingelöst
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item class="list-item-hover-active pb-2" @click="filter = 'Nicht eingelöst'">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-body-2 ls-0 text-typo font-weight-600 mb-0"
                  
                >
                  Status: Nicht eingelöst
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <hr class="horizontal dark" />
            <v-list-item class="list-item-hover-active py-2" @click="filter = ''">
              <v-list-item-content class="pa-0">
                <v-list-item-title
                  class="text-danger ls-0 font-weight-600 mb-0"
                  
                >
                  Filter entfernen
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          elevation="0"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default btn-outline-default
            shadow-none
            py-2
            px-6
            me-2
          "
          color="transparent"
          small
          @click="csvExportEntries(event)"
        >
          <v-icon>ni ni-archive-2 me-1</v-icon>
          Export CSV
        </v-btn>
      </div>
    </div>
    <v-card class="card-shadow border-radius-xl">
      <div class="card-header-padding">
        <v-row class="mt-0 ml-1" justify="space-between">
                <h5 class="font-weight-bolder text-h5 text-typo">
                 {{ event.entries.length }} Anmeldungen
                </h5>
                <div class="mr-2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="$store.dispatch('getEventsAdmin')"
                        v-bind="attrs"
                        v-on="on"
                        class="material-icons-round text-primary ms-auto"
                        size="30"
                      >
                        refresh
                      </v-icon>
                    </template>
                    <span>Aktualisieren</span>
                  </v-tooltip>
                </div>
              </v-row>
        <!-- <h5 class="font-weight-bold text-h5 text-typo mb-0">
          {{ event.entries.length }} Anmeldungen
        </h5> -->
      </div>

      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="
            event.entries.filter((entry) =>
              filter != ''
                ? filter == 'Eingelöst'
                  ? entry.redeemed == true
                  : !entry.redeemed
                : true
            )
          "
          :search="search"
          class="table"
          :page.sync="page"
          hide-default-footer
          @page-count="pageCount = $event"
          :items-per-page="itemsPerPage"
          mobile-breakpoint="0"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="5">
                  <v-text-field
                    hide-details
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      input-icon
                    "
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    placeholder="Suche"
                  >
                    <template slot="prepend-inner">
                      <v-icon
                        color="#adb5bd"
                        size="16"
                        class="material-icons-round mt-n2"
                        >search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-spacer></v-spacer>

              <v-dialog v-model="dialog" max-width="600px">
                <v-card class="card-shadow border-radius-xl">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">{{
                      formTitle
                    }}</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.firstname"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Vorname"
                            label="Vorname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.lastname"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Nachname"
                            label="Nachname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.email"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="E-Mail-Adresse"
                            label="E-Mail-Adresse"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.street"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Straße"
                            label="Straße"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.number"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Hausnummer"
                            label="Hausnummer"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.zip"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Postleitzahl"
                            label="Postleitzahl"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.city"
                            hide-details
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Ort"
                            label="Ort"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-btn
                            @click="
                              emailResended ? null : resendEmail(editedItem)
                            "
                            elevation="0"
                            :ripple="false"
                            height="43"
                            class="
                              font-weight-normal
                              text-capitalize
                              btn-ls btn-primary
                              py-3
                              px-6
                            "
                            :class="
                              emailResended == editedItem._id
                                ? 'bg-gradient-success'
                                : 'bg-gradient-info'
                            "
                            >{{
                              emailResended == editedItem._id
                                ? "Ticket-E-Mail gesendet!"
                                : "Ticket-E-Mail erneut senden"
                            }}</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >

                    <v-btn
                      @click="save"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-primary
                        bg-gradient-primary
                        py-3
                        px-6
                      "
                      >speichern</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="showParticipantsDialog" max-width="600px">
                <v-card class="card-shadow card-padding border-radius-xl" v-if="!editedItem.participant_data">
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">Teilnehmerdaten</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.firstname"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Vorname"
                            label="Vorname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.lastname"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Nachname"
                            label="Nachname"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.email"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="E-Mail-Adresse"
                            label="E-Mail-Adresse"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.street"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Straße"
                            label="Straße"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.zip"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Postleitzahl"
                            label="Postleitzahl"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.city"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Ort"
                            label="Ort"
                          ></v-text-field>
                        </v-col>
                        <!-- <v-col cols="6">
                          <v-btn
                            @click="
                              emailResended ? null : resendEmail(editedItem)
                            "
                            elevation="0"
                            :ripple="false"
                            height="43"
                            class="
                              font-weight-normal
                              text-capitalize
                              btn-ls btn-primary
                              py-3
                              px-6
                            "
                            :class="
                              emailResended == editedItem._id
                                ? 'bg-gradient-success'
                                : 'bg-gradient-info'
                            "
                            >{{
                              emailResended == editedItem._id
                                ? "Ticket-E-Mail gesendet!"
                                : "Ticket-E-Mail erneut senden"
                            }}</v-btn
                          >
                        </v-col> -->
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <v-card class="card-shadow border-radius-xl" v-else>
                  <div class="card-header-padding card-border-bottom">
                    <span class="font-weight-bold text-h5 text-typo mb-0">Angemeldeter Teilnehmer</span>
                  </div>
                  <v-card-text class="card-padding">
                    <v-container class="px-0">
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.participant_data.name"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Name"
                            label="Name"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.participant_data.street"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Straße"
                            label="Straße"
                          ></v-text-field>
                        </v-col>
                        
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.participant_data.zipcode"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Postleitzahl"
                            label="Postleitzahl"
                            type="number"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="editedItem.participant_data.city"
                            hide-details
                            disabled
                            class="
                              input-style
                              font-size-input
                              text-light-input
                              placeholder-light
                              input-icon
                            "
                            dense
                            flat
                            outlined
                            height="43"
                            placeholder="Ort"
                            label="Ort"
                          ></v-text-field>
                        </v-col>
                        
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions class="card-padding d-flex justify-end">
                    <v-btn
                      @click="close"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls btn-outline-secondary
                        bg-transparent
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >

                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogNoInvoice" max-width="500px">
                <v-card class="card-shadow card-padding border-radius-xl">
                  <v-card-title class="pt-0 text-h5 text-typo justify-center"
                    >Keine Rechnung vorhanden</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeNoInvoice"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="
                        font-weight-normal
                        text-capitalize
                        btn-ls
                        bg-transparent
                        btn-outline-secondary
                        py-3
                        px-6
                      "
                      >schließen</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <template v-slot:item.firstname="{ item }">
            <div class="d-flex align-center ms-2">
              <span class="text-sm font-weight-normal text-body">
                {{ item.participant_data ? item.participant_data.name : item.firstname + ' ' + item.lastname }}
              </span>
            </div>
          </template>

          <template v-slot:item.email="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.email }}
            </span>
          </template>

          <template v-slot:item.age="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.age }}
            </span>
          </template>

          <template v-slot:item.salary="{ item }">
            <span class="text-sm font-weight-normal text-body">
              {{ item.salary }}
            </span>
          </template>

          <template v-slot:item.redeemed="{ item }">
            <div class="d-flex align-center" style="max-width: 50px">
              <v-btn
                @click="updateRedeemed(item)"
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2"
                :class="item.redeemed ? 'border-success' : 'border-danger'"
              >
                <v-icon
                  size="8"
                  :class="item.redeemed ? 'text-success' : 'text-danger'"
                >
                  {{ item.redeemed ? "fas fa-check" : "fas fa-times" }}
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">{{ item.status }}</span>
            </div>
          </template>

          <template v-slot:item.email_verified="{ item }">
            <div class="d-flex align-center">
              <v-btn
                :ripple="false"
                icon
                outlined
                rounded
                width="25px"
                height="25px"
                class="me-2"
                :class="
                  item.email_verified ? 'border-success' : 'border-default'
                "
              >
                <v-icon
                  size="8"
                  :class="item.email_verified ? 'text-success' : 'text-dark'"
                >
                  {{ item.email_verified ? "fas fa-check" : "fas fa-times" }}
                </v-icon>
              </v-btn>
              <span class="text-body text-xs">{{ item.status }}</span>
            </div>
          </template>

          <template v-slot:item._id="{ item }">
            <v-btn
              @click="editItem(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">search</v-icon>
            </v-btn>

            <v-btn
              @click="showParticipants(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">person</v-icon>
            </v-btn>

            <!-- <v-btn
              @click="downloadInvoiceModal(item)"
              icon
              elevation="0"
              :ripple="false"
              height="28"
              min-width="36"
              width="36"
              class="btn-ls me-2 my-2 rounded-sm"
              color="#67748e"
            >
              <v-icon size="25" class="material-icons-round">download</v-icon>
            </v-btn> -->
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="card-padding">
        <v-row>
          <v-col cols="6" lg="3" class="d-flex align-center">
            <span class="text-body me-3 text-sm">Einträge pro Seite:</span>
            <v-text-field
              hide-details
              type="number"
              outlined
              min="-1"
              max="15"
              background-color="rgba(255,255,255,.9)"
              color="rgba(0,0,0,.6)"
              light
              :value="itemsPerPage"
              @input="itemsPerPage = parseInt($event, 10)"
              placeholder="Items per page"
              class="
                font-size-input
                placeholder-lighter
                text-color-light
                input-alternative input-focused-alternative input-icon
              "
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" class="ml-auto d-flex justify-end">
            <v-pagination
              prev-icon="fa fa-angle-left"
              next-icon="fa fa-angle-right"
              class="pagination"
              color="#D81B60"
              v-model="page"
              :length="pageCount"
              circle
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import users from "./Users";
import coreData from "@/mixins/coreDataMixin";

export default {
  name: "paginated-tables",
  mixins: [coreData],
  data() {
    return {
      filter: "",
      dialogNoInvoice: false,
      showParticipantsDialog: false,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      users,
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      headers: [
        
        {
          text: "Teilnehmer",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "firstname",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Eingelöst",
          value: "redeemed",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        // {
        //   text: "Nachname",
        //   value: "lastname",
        //   class: "text-secondary font-weight-bolder opacity-7",
        // },
        {
          text: "Anmelder E-Mail",
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Bestätigt",
          value: "email_verified",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Aktionen",
          value: "_id",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showParticipants(item) {
      this.editedItem = item;
      // this.editedItem = Object.assign({}, item);
      this.showParticipantsDialog = true;
    },
    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.showParticipantsDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },

    closeNoInvoice() {
      this.dialogNoInvoice = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      // });
    },

    downloadInvoiceModal(item) {
      if (item.payment_id.length == 0) {
        this.dialogNoInvoice = true;
      } else {
        this.downloadInvoice(item);
      }
    },

    save() {
      // if (this.editedIndex > -1) {
      //   Object.assign(this.users[this.editedIndex], this.editedItem);
      // } else {
      //   this.users.push(this.editedItem);
      // }
      this.updateEventEntry(this.editedItem);
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return "Rechnungsdaten Anmelder";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>

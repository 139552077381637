<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  > 

    <v-col cols="12" md="8">
    <v-container fluid v-if="event">
      <v-row class="mb-6 mt-15" justify="center">
        <v-col
          v-for="(item, i) in [event]"
          :key="item.title + i"
          class="pt-0 mb-10 px-0"
        >
          <v-card
            class="border-radius-xl text-start"
           
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  position="top center"
                  fill
                  contain
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>
            <div class="px-5 mt-10 mb-10">
              <v-row align="center" justify="center" class="h-100">
                <div>
                  <h2 class="font-weight-normal text-typo text-h2 px-4">
                    {{ item.title }}
                  </h2>
                </div>
              </v-row>
              <v-row align="center" justify="center" class="h-100">
                <div>
                  <h3 class="font-weight-normal text-typo text-h3 px-4">
                    {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
                  </h3>
                </div>
              </v-row>
            </div>
            <div>
              <p
                class="mx-5 text-start text-typo"
                v-html="item.description"
              ></p>
            </div>

            <v-card-title>Veranstaltungsinformationen</v-card-title>

            <v-divider class="mx-4"></v-divider>

            <v-card-text>
              <div class="text-subtitle-1">
                {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
              </div>
              <div class="mt-4 text-subtitle-1">
                {{ item.street + " " + item.zipcode + ", " + item.city }}
              </div>
              <div
                class="mt-4 text-subtitle-1"
                v-if="item.website && item.website != 'undefined'"
              >
                Veranstalter:
                <a :href="item.website" target="_blank">{{ item.organizer }}</a>
              </div>
              <div class="mt-4 text-subtitle-1" v-else>
                Veranstalter: {{ item.organizer }}
              </div>
              <div class="mt-4" style="font-weight: bold; font-size: 20px">
                Eintrittspreis:
                {{ item.price > 0 ? 'Online: ' + item.price + "€" : "Gratis" }}{{ item.price_lowered > 0 ? ' • Ermäßigt: ' + item.price_lowered + "€" : "" }} {{ item.price_local > 0 ? ' • Abendkasse: ' + item.price_local + "€" : "" }}
              </div>
            </v-card-text>

            <v-divider class="mx-4"></v-divider>

            <div v-if="item.hints && item.hints != 'undefined'" style="max-width: 90vw">
              <v-expansion-panels flat color="#ddd" class="mb-5 pl-5 pr-5">
                <v-expansion-panel class="bg-transparent">
                  <v-expansion-panel-header class="border-bottom ml-0 px-0">
                    <v-card-title class="pl-0">Weitere Hinweise</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 ma-0">
                    <div class="mt-5">
                      <span
                        class="pr-10"
                        v-html="item.hints"
                      ></span>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel class="bg-transparent" v-if="event.price_lowered_info">
                  <v-expansion-panel-header class="border-bottom ml-0 px-0">
                    <v-card-title class="pl-0">Ermäßgungshinweise</v-card-title>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="px-0 ma-0">
                    <div class="mt-5">
                      <span
                        class="pr-10"
                        v-html="event.price_lowered_info"
                      ></span>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>

            <v-card-title>Verfügbare Karten:</v-card-title>

            <v-card-text>
              <v-chip-group column>
                <v-chip
                  >{{ calcSlots }} /
                  {{ item.slots }}</v-chip
                >
              </v-chip-group>
            </v-card-text>
            <v-divider class="mx-4"></v-divider>
            <v-row class="mx-4 my-10" justify="space-around">
              <!-- <div>
                <span style="font-size: 30px">
                  {{ item.price > 0 ? item.price + "€" : "Abendkasse" }}
                </span>
              </div> -->
              <v-btn
                elevation="0"
                height="43"
                class="
                  font-weight-bold
                  text-uppercase
                  btn-default
                "
                :class="bookable ? 'bg-gradient-success' : 'bg-gradient-danger'"
                :disabled="!bookable"
                color="#5e72e4"
                small
                @click="
                  $router
                    .push('/events/order/' + event._id)
                    .catch(() => {})
                "
                >{{bookable ? 'Jetzt Buchen!' : 'Leider ausgebucht!' }}</v-btn
              >
            </v-row>
            <v-divider class="mx-4"></v-divider>
            <v-card-text>
              <div class="grey--text">
                {{ item.manager.username }}
              </div>
              <p class="my-auto text-body font-weight-light">
                {{ item.updated | moment("DD.MM.YYYY HH:mm") }} Uhr
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="border-radius-xl mt-5">
        <v-card-text>
          <v-expansion-panels flat color="#ddd" class="mb-8">
            <v-expansion-panel class="bg-transparent">
              <v-expansion-panel-header class="border-bottom px-0">
                <h5 class="text-h5 font-weight-bold text-body">
                  {{ eventComments.length }} Kommentare
                </h5>
              </v-expansion-panel-header>
              <v-expansion-panel-content >
                <div v-if="eventComments.length == 0">
                  <h5 class="text-h5 text-typo font-weight-bold mt-0 mb-2">
                    Keine Kommentare vorhanden
                  </h5>
                </div>
                <div v-else>
                  <div class="d-flex mt-5">
                    <div class="flex-grow-1 ms-4">
                      <div
                        :key="index"
                        v-for="(comment, index) in eventComments"
                      >
                        <v-list-item
                          :ripple="false"
                          class="px-0 border-radius-sm mb-2"
                        >
                          <v-avatar
                            width="48"
                            height="48"
                            class="shadow border-radius-lg me-4"
                          >
                            <img
                              v-if="comment.user.avatar"
                              :src="getImage(comment.user.avatar)"
                              alt="Avatar"
                              class="border-radius-lg"
                            />
                            <div v-else>
                              {{ comment.user.username.charAt(0)
                              }}{{ comment.user.username.charAt(1) }}
                            </div>
                          </v-avatar>
                          <v-list-item-content>
                            <div class="d-flex align-center">
                              <div>
                                <h6
                                  class="
                                    mb-0
                                    text-sm text-sm text-typo
                                    font-weight-bold
                                  "
                                >
                                  {{ comment.user.username }}
                                </h6>
                                <p
                                  class="
                                    mb-0
                                    text-xs text-body
                                    font-weight-light
                                  "
                                >
                                  {{ comment.comment }}
                                </p>
                              </div>
                              <div class="ms-auto"></div>
                            </div>
                          </v-list-item-content>
                        </v-list-item>
                        <p class="text-xs mt-2 font-weight-light text-body">
                          {{ comment.created | moment("DD.MM.YYYY HH:mm") }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <h2
        class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
        style="font-size: 35px"
        v-if="events.length > 0"
      >
        Weitere Veranstaltungen
      </h2>
      <v-row class="mb-6 mt-15" justify="space-around">
        <v-col
          v-for="(item, i) in events.filter(
            (eventF) => eventF._id != event._id
          )"
          :key="item.title + i"
          lg="4"
          md="6"
          sm="12"
          cols="12"
          class="pt-0 mb-0"
        >
          <event-card :item="item"></event-card>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="!event && !loadingEvents">
      <h2
        class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
        style="font-size: 35px"
      >
        Hoppla.... <br />
        Wir haben diese Veranstaltung nicht gefunden...
      </h2>
      <v-row justify="center">
        <div>
          <v-img
            height="100%"
            width="400px"
            :src="errorImage"
            class="img-fluid border-radius-lg"
            :alt="errorImage"
          />
        </div>
      </v-row>
    </v-container>
    <sponsors></sponsors>

    <faq></faq>
    </v-col>
  </v-row>
</template>
<script>
import EventCard from "@/components/Widgets/EventCard.vue";
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
export default {
  name: "EventDetails",
  components: {
    EventCard,
    Sponsors,
    faq,
  },
  computed: {
    calcSlots(){
      try {
        return (this.event.slots - this.event.entries.length) <= 0 ? 0 : this.event.slots - this.event.entries.length
      } catch (error) {
        return 0
      }
    },
    bookable(){
      if(this.calcSlots > 0){
        return true
      } else {{{ item.price > 0 ? 'Online: ' + item.price + "€" : "Gratis" }}{{ item.price_lowered > 0 ? ' • Erm.: ' + item.price_lowered + "€" : "" }} {{ item.price_local > 0 ? ' • Abendk.: ' + item.price_local + "€" : "" }}
        return false
      }
    },
    loadingEvents() {
      return this.$store.getters.loadingEvents;
    },
    event() {
      return this.$store.getters.event;
    },
    events() {
      return this.$store.getters.events;
    },
    eventComments() {
      return this.$store.getters.eventComments;
    },
  },
  mounted() {
    this.$store.dispatch("getEventByID", this.$route.params.id);
    this.$store.dispatch("getEvents");
    window.scrollTo(0, 0);
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
    };
  },
};
</script>
<style scoped>
 .v-expansion-panel-content__wrap {
   padding: 0 !important
 }
</style>
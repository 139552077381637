<template>
  <v-card :loading="loading" class="mx-auto my-12 shadow" max-width="374" :href="'/news/' + (item.url ? item.url : item._id)"
            @click="$router.push('/news/' + (item.url ? item.url : item._id)).catch(() => {})">
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img height="250" :src="getImage(item.img)"></v-img>

    <v-card-title style="height: 100px; overflow: hidden">{{ item.title }}</v-card-title>

    <v-card-text>
      <div class="grey--text">
        {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
      </div>

      <div class="text-start font-weight-normal text-typo" style="height: 70px; overflow: hidden"
                v-html="
                  item.description.length > 150
                    ? item.description.substr(0, 150) + ' ...'
                    : item.description
                "> 
      </div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

    <v-card-actions>
      <v-btn color="success lighten-2" text>
        Jetzt Ansehen
      </v-btn>
    </v-card-actions>
  </v-card>
  <!-- <v-card
            class="card-shadow border-radius-xl py-5 text-center"
            @click="$router.push('/news/' + item._id).catch(() => {})"
          >
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <v-img
                  position="top center"
                  fill
                  height="250px"
                  :src="getImage(item.img)"
                  class="img-fluid shadow border-radius-lg"
                  :alt="item.image"
                />
              </div>
              <div
                class="colored-shadow"
                v-bind:style="{
                  backgroundImage: 'url(' + getImage(item.img) + ')',
                }"
              ></div>
            </div>
            <div style="height: 80px" class="px-5 mt-3">
              <v-row align="center" justify="center" class="h-100">
                <div>
                  <h5 class="font-weight-normal text-typo text-h5 px-4">
                    {{ item.title }}
                  </h5>
                </div>
              </v-row>
            </div>
            <div>
              <p
                class="mx-5 text-start text-typo"
                v-html="
                  item.description.length > 200
                    ? item.description.substr(0, 200) + ' ... weiter lesen.'
                    : item.description
                "
              ></p>
            </div>
            <hr class="horizontal dark mb-6" />
            <div class="">
              <p class="my-auto text-body font-weight-light">
                {{ item.manager.username }}
              </p>
            </div>
            <div class="">
              <p class="my-auto text-body font-weight-light">
                {{ item.date | moment("DD.MM.YYYY HH:mm") }} Uhr
              </p>
            </div>
          </v-card> -->
</template>

<script>
export default {
  name: 'NewsCard',
  props: {
    item: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    getImage(link) {
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },
  },
};
</script>

<style>
</style>
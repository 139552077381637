<template>
  <div>
    <v-container fluid class="" v-if="event">
      <v-row v-if="userInformations && userInformations.emailVerified">
        <v-col cols="12" class="text-center mx-auto">
          <h3 class="text-h3 text-typo font-weight-bold mt-9">
            Eintrittskarte(n) Bestellen
          </h3>
          <h5 class="text-h5 text-secondary font-weight-normal">
            Wir benötigen einige Informationen für die
            {{ event.price > 0 ? "Bestellung" : "Reservierung" }}
          </h5>
        </v-col>
        <v-col lg="8" class="mx-auto" v-if="userInformations && bookable">
          <v-card class="shadow border-radius-xl my-10 mx-auto">
            <v-stepper
              alt-labels
              elevation="0"
              v-model="e1"
              class="bg-transparent overflow-visible"
            >
              <v-row>
                <v-col cols="12" class="mx-auto pt-1">
                  <v-stepper-header
                    class="
                      bg-gradient-default
                      shadow-primary
                      border-radius-lg
                      mx-4
                      mt-n4
                    "
                  >
                    <v-stepper-step step="1" color="#344767">
                      <span
                        class="
                          text-white
                          opacity-7
                          text-xs
                          font-weight-normal
                          text-uppercase
                        "
                        >Anzahl</span
                      >
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="2" color="#344767">
                      <span
                        class="
                          text-white
                          opacity-7
                          text-xs
                          font-weight-normal
                          text-uppercase
                        "
                        >Persönliche Daten</span
                      >
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="3" color="#344767">
                      <span
                        class="
                          text-white
                          opacity-7
                          text-xs
                          font-weight-normal
                          text-uppercase
                        "
                        >Teilnehmer Daten</span
                      >
                    </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step step="4" color="#344767">
                      <span
                        class="
                          text-white
                          opacity-7
                          text-xs
                          font-weight-normal
                          text-uppercase
                        "
                        >Abschluss</span
                      >
                    </v-stepper-step>
                  </v-stepper-header>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="mx-auto">
                  <v-stepper-items
                    class="border-radius-xl overflow-hidden mt-5"
                  >
                    <v-stepper-content
                      step="1"
                      class="bg-white border-radius-xl px-4 pt-0"
                    >
                      <v-card>
                        <div>
                          <v-row class="text-center">
                            <v-col cols="10" class="mx-auto">
                              <h5
                                class="
                                  text-h5 text-typo
                                  font-weight-normal
                                  mb-2
                                "
                              >
                                {{ event.title }}
                              </h5>
                              <p class="text-body font-weight-light">
                                {{
                                  event.street +
                                  " " +
                                  event.zipcode +
                                  ", " +
                                  event.city
                                }}
                              </p>
                              <p class="text-body font-weight-light">
                                {{ event.date | moment("DD.MM.YYYY HH:mm") }}
                                Uhr
                              </p>
                            </v-col>
                          </v-row>
                          <v-row class="mt-2">
                            <v-col>
                              <div class="text-center" style="font-size: 20px">
                                <div>Freie Plätze</div>
                                <div>
                                  {{ event.slots - event.entries.length }}
                                </div>
                              </div>
                            </v-col>
                            <v-col>
                              <div class="text-center" style="font-size: 20px">
                                <div>Kartenpreis</div>
                                <div class="">
                                  {{
                                    event.price > 0
                                      ? event.price + "€"
                                      : "Gratis"
                                  }}
                                </div>
                                <div>
                                  {{
                                    event.price_lowered > 0
                                      ? event.price_lowered + "€ (ermäßigt)"
                                      : ""
                                  }}
                                </div>
                              </div>
                            </v-col>
                          </v-row>
                          <v-row class="mt-2">
                            <v-col>
                              <div class="text-center" style="font-size: 25px">
                                <div>Anzahl Tickets</div>
                                <v-row justify="center" class="mt-2">
                                  <div class="mx-3">
                                    <v-btn
                                      :disabled="orderData.amount <= 1"
                                      :elevation="0"
                                      class="
                                        font-weight-bold
                                        text-xs
                                        btn-default
                                      "
                                      :class="
                                        orderData.amount <= 1
                                          ? 'bg-gradient-danger'
                                          : 'bg-gradient-success'
                                      "
                                      color="primary"
                                      @click="subAmount"
                                    >
                                      -
                                    </v-btn>
                                  </div>
                                  <div class="mx-3">
                                    {{ orderData.amount }}
                                  </div>
                                  <div class="mx-3">
                                    <v-btn
                                      :elevation="0"
                                      class="
                                        font-weight-bold
                                        text-xs
                                        btn-default
                                      "
                                      :class="
                                        orderData.amount >=
                                        event.slots - event.entries.length
                                          ? 'bg-gradient-danger'
                                          : 'bg-gradient-success'
                                      "
                                      color="primary"
                                      @click="addAmount"
                                    >
                                      +
                                    </v-btn>
                                  </div>
                                </v-row>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="text-end mt-10">
                          <v-btn
                            :elevation="0"
                            class="
                              font-weight-bold
                              text-xs
                              btn-default
                              bg-gradient-default
                              py-5
                              px-6
                              mt-2
                              mb-2
                              me-2
                            "
                            color="primary"
                            @click="addEl()"
                          >
                            Weiter
                          </v-btn>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content
                      step="2"
                      class="bg-white border-radius-xl px-4 pt-4"
                    >
                      <v-card>
                        <div class="px-5">
                          <v-row class="text-center">
                            <v-col cols="10" class="mx-auto">
                              <h5
                                class="
                                  text-h5 text-typo
                                  font-weight-normal
                                  mb-2
                                "
                              >
                                Persönliche Daten
                              </h5>
                              <p class="text-body font-weight-light">
                                Wir verwenden Deine persönlichen Daten für die
                                Rechnungserstellung und Zuordnung der Tickets
                              </p>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12">
                              <v-text-field
                                label="E-Mail-Adresse"
                                color="#e91e63"
                                disabled
                                required
                                :rules="rules"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.email"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12">
                              <v-text-field
                                label="Firma (optional)"
                                color="#e91e63"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.companyName"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12">
                              <v-text-field
                                label="Vorname"
                                color="#e91e63"
                                required
                                :rules="rules"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.firstname"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12">
                              <v-text-field
                                label="Nachname"
                                color="#e91e63"
                                required
                                :rules="rules"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.lastname"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12">
                              <v-text-field
                                label="Straße"
                                color="#e91e63"
                                required
                                :rules="rules"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.street"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <v-row class="mt-0">
                            <v-col cols="12" md="6" lg="4">
                              <v-text-field
                                type="number"
                                label="Postleitzahl"
                                color="#e91e63"
                                required
                                :rules="rules"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.zipcode"
                              >
                              </v-text-field>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                              <v-text-field
                                label="Ort"
                                color="#e91e63"
                                required
                                :rules="rules"
                                class="font-size-input input-style"
                                v-model="orderData.userInformations.city"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </div>
                        <div class="d-flex mt-10">
                          <v-btn
                            :elevation="0"
                            class="
                              font-weight-bold
                              text-xs text-dark
                              shadow-none
                              bg-transparent
                              btn-outline-secondary
                              py-5
                              px-6
                              mt-6
                              mb-2
                              ms-2
                            "
                            @click="subEl()"
                          >
                            {{ "zurück" }}
                          </v-btn>

                          <v-btn
                            v-if="!userInformations"
                            :elevation="0"
                            @click="$store.commit('popLogin', true)"
                            class="
                              font-weight-bold
                              text-xs
                              btn-default
                              bg-gradient-warning
                              py-5
                              px-6
                              mt-6
                              mb-2
                              me-2
                              ms-auto
                            "
                            color="primary"
                          >
                            Einloggen
                          </v-btn>

                          <v-btn
                            :elevation="0"
                            :disabled="!verifySteps.step2"
                            class="
                              font-weight-bold
                              text-xs
                              btn-default
                              bg-gradient-default
                              py-5
                              px-6
                              mt-6
                              mb-2
                              me-2
                              ms-auto
                            "
                            color="primary"
                            @click="addEl()"
                          >
                            weiter
                          </v-btn>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content
                      step="3"
                      class="bg-white border-radius-xl px-4 pt-4"
                    >
                      <v-card>
                        <div class="px-5">
                          <v-row class="text-center">
                            <v-col cols="10" class="mx-auto">
                              <h5
                                class="
                                  text-h5 text-typo
                                  font-weight-normal
                                  mb-2
                                "
                              >
                                Teilnehmer Daten
                              </h5>
                              <p class="text-body font-weight-light">
                                Aus Sicherheitsgründen werden zusätzliche Daten
                                der Teilnehmer benötigt <br />
                                Bitte stelle sicher, dass die korrekten Daten
                                angegeben werden, da in manchen Fällen die
                                Identität bestätigt werfden muss.
                              </p>
                              <p class="text-body font-weight-light"></p>
                            </v-col>
                          </v-row>
                          <div
                            class="mb-10"
                            :key="index"
                            v-for="(
                              participant, index
                            ) in orderData.participants"
                          >
                            <h5 class="text-h5 text-typo font-weight-normal">
                              Teilnehmer {{ index + 1 }}
                            </h5>
                            <v-row class="mt-0">
                              <v-col cols="12">
                                <v-text-field
                                  label="Vor- und Nachname"
                                  color="#e91e63"
                                  required
                                  :rules="rules"
                                  class="font-size-input input-style"
                                  v-model="participant.name"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mt-0">
                              <v-col cols="12">
                                <v-text-field
                                  label="Straße & Hausnummer"
                                  color="#e91e63"
                                  required
                                  :rules="rules"
                                  class="font-size-input input-style"
                                  v-model="participant.street"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>

                            <v-row class="mt-0">
                              <v-col cols="12" md="6" lg="4">
                                <v-text-field
                                  type="number"
                                  label="Postleitzahl"
                                  color="#e91e63"
                                  required
                                  :rules="rules"
                                  class="font-size-input input-style"
                                  v-model="participant.zipcode"
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12" md="6" lg="4">
                                <v-text-field
                                  label="Ort"
                                  color="#e91e63"
                                  required
                                  :rules="rules"
                                  class="font-size-input input-style"
                                  v-model="participant.city"
                                >
                                </v-text-field>
                              </v-col>

                              <v-col cols="12">
                                <v-row justify="start" align="center" class="ml-1">
                                  <div>
                                    <v-checkbox
                                      v-model="participant.price_lowered"
                                      label="Ermäßigt"
                                    >
                                    </v-checkbox>
                                  </div>

                                  <div>
                                    
                                    <v-btn
                                      class="mx-2 ml-5"
                                      @click="priceLoweredInfoDialog = true"
                                      fab
                                      dark
                                      x-small
                                      color="primary"
                                    >
                                      <i
                                        class="
                                          material-icons-round
                                          position-relative
                                          text-lg
                                        "
                                        >info</i
                                      >
                                    </v-btn>
                                    
                                  </div>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                        </div>
                        <div class="d-flex mt-10">
                          <v-btn
                            :elevation="0"
                            class="
                              font-weight-bold
                              text-xs text-dark
                              shadow-none
                              bg-transparent
                              btn-outline-secondary
                              py-5
                              px-6
                              mt-6
                              mb-2
                              ms-2
                            "
                            @click="subEl()"
                          >
                            {{ "zurück" }}
                          </v-btn>
                          <v-btn
                            :elevation="0"
                            :disabled="!verifySteps.step3"
                            class="
                              font-weight-bold
                              text-xs
                              btn-default
                              bg-gradient-default
                              py-5
                              px-6
                              mt-6
                              mb-2
                              me-2
                              ms-auto
                            "
                            color="primary"
                            @click="addEl()"
                          >
                            weiter
                          </v-btn>
                        </div>
                      </v-card>
                    </v-stepper-content>

                    <v-stepper-content
                      step="4"
                      class="bg-white border-radius-xl px-4 pt-0"
                    >
                      <v-card>
                        <div>
                          <v-row class="text-center">
                            <v-col cols="10" class="mx-auto">
                              <h5
                                class="
                                  text-h5 text-typo
                                  font-weight-normal
                                  mb-2
                                "
                              >
                                {{
                                  event.price > 0
                                    ? "Bestellung"
                                    : "Reservierung"
                                }}
                                abschließen
                              </h5>
                              <p class="text-body font-weight-light">
                                Bestellübersicht
                              </p>
                            </v-col>
                          </v-row>
                          <div
                            :key="index"
                            v-for="(order, index) in orderData.participants"
                          >
                            <v-row class="mt-2" align="center">
                              <v-col>
                                <div
                                  class="text-center"
                                  style="font-size: 16px"
                                >
                                  <div>
                                    Eintrittskarte
                                    {{
                                      order.price_lowered ? " (ermäßigt)" : ""
                                    }}
                                  </div>
                                  <div>
                                    {{ order.name }}
                                  </div>
                                </div>
                              </v-col>
                              <v-col>
                                <div
                                  class="text-center"
                                  style="font-size: 20px"
                                >
                                  <div>
                                    {{
                                      order.price_lowered
                                        ? event.price_lowered.toFixed(2)
                                        : event.price.toFixed(2)
                                    }}€
                                  </div>
                                </div>
                              </v-col>
                            </v-row>
                            <!-- <v-divider class="mx-8 my-4"></v-divider> -->
                          </div>
                          <v-divider class="mx-8 my-4"></v-divider>
                          <v-row class="mt-2" align="center">
                            <v-col>
                              <div class="text-center" style="font-size: 16px">
                                <div>Gesamtpreis</div>
                                <div v-if="event.manager.vatNumber">
                                  (inkl. 19% Mehrwertsteuer)
                                </div>
                              </div>
                            </v-col>
                            <v-col>
                              <div class="text-center" style="font-size: 20px">
                                <div>{{ calcTotal.toFixed(2) }}€</div>
                              </div>
                            </v-col>
                          </v-row>
                          <div v-if="event.price > 0">
                            <v-row
                              class="mt-2"
                              align="center"
                              justify="center"
                              v-if="!paymentReady"
                            >
                              <div class="mt-10">
                                <p>Bestellung bezahlen</p>
                              </div>
                            </v-row>

                            <v-row class="mt-2" align="center" justify="center">
                              <div class="mt-0">
                                <v-card
                                  @click="submitOrder"
                                  v-if="!paymentReady && !updatingPayment"
                                >
                                  <v-img
                                    v-if="!updatingPayment"
                                    src="@/assets/img/logos/paypal.png"
                                    alt="logo"
                                    max-width="280"
                                    contain
                                  ></v-img>
                                </v-card>

                                <v-btn
                                  v-if="updatingPayment && !paymentReady"
                                  :elevation="0"
                                  :disabled="!verifySteps.step2"
                                  width="200px"
                                  class="
                                    font-weight-bold
                                    text-xs
                                    btn-default
                                    bg-gradient-default
                                    py-5
                                    px-6
                                    mt-6
                                    mb-2
                                    me-2
                                    ms-auto
                                  "
                                  color="primary"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="white"
                                  ></v-progress-circular>
                                </v-btn>

                                <v-card v-if="paymentReady" class="mt-10">
                                  <span class="text-center">
                                    <!-- <small>
                                       Sie können Ihre Zahlungen unter "Mein Bereich -> Zahlungen" einsehen
                                  </small> -->
                                  </span>
                                  <v-row
                                    class="mt-2"
                                    align="center"
                                    justify="center"
                                  >
                                    <div>
                                      <v-btn
                                        v-if="paymentId"
                                        :elevation="0"
                                        :disabled="!verifySteps.step2"
                                        class="
                                          font-weight-bold
                                          text-xs
                                          btn-default
                                          bg-gradient-default
                                          py-5
                                          px-6
                                          mt-6
                                          mb-2
                                          me-2
                                          ms-auto
                                        "
                                        color="primary"
                                        @click="checkPayment()"
                                      >
                                        <span>Bestellung Prüfen</span>
                                      </v-btn>
                                    </div>
                                  </v-row>
                                </v-card>
                              </div>
                            </v-row>
                            <p class="text-center font-weight-light mt-15">
                              Es öffnet sich ein PayPal Fenster. <br />
                              Schließen Sie dieses Fenster nicht bevor die
                              Zahlung abgeschlossen wurde. <br />
                              Nach Abschluss der Zahlung können Sie hier die
                              Bestellung abschließen.
                            </p>
                            <!-- <v-row class="mt-2" align="center" justify="center" v-if="paymentReady">
                              <div class="mt-10">
                                <v-btn
                                  :elevation="0"
                                  class="
                                    font-weight-bold
                                    text-xs
                                    btn-default
                                    bg-gradient-default
                                    py-5
                                    px-6
                                    mt-6
                                    mb-2
                                    me-2
                                    ms-auto
                                  "
                                  color="primary"
                                  @click="submitOrder"
                                >
                                  
                                </v-btn>
                              </div>
                            </v-row> -->
                          </div>
                          <div v-else>
                            <v-row class="mt-2" align="center" justify="center">
                              <div class="mt-10">
                                <v-btn
                                  :elevation="0"
                                  :disabled="!verifySteps.step2"
                                  class="
                                    font-weight-bold
                                    text-xs
                                    btn-default
                                    bg-gradient-default
                                    py-5
                                    px-6
                                    mt-6
                                    mb-2
                                    me-2
                                    ms-auto
                                  "
                                  color="primary"
                                  @click="submitOrder"
                                >
                                  Reservierung bestätigen
                                </v-btn>
                              </div>
                            </v-row>
                          </div>
                        </div>
                        <div class="d-flex mt-10">
                          <v-btn
                            :elevation="0"
                            class="
                              font-weight-bold
                              text-xs text-dark
                              shadow-none
                              bg-transparent
                              btn-outline-secondary
                              py-5
                              px-6
                              mt-6
                              mb-2
                              ms-2
                            "
                            @click="subEl()"
                          >
                            {{ "zurück" }}
                          </v-btn>
                        </div>
                      </v-card>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-col>
              </v-row>
            </v-stepper>
          </v-card>
        </v-col>
        <v-col lg="8" class="mx-auto" v-if="!bookable">
          <h2
            class="mx-3 h1 font-weight-normal text-center mt-10 text-danger"
            style="font-size: 35px"
          >
            Veranstaltung ist leider ausgebucht!
          </h2>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" class="text-center mx-auto">
          <h3 class="text-h3 text-typo font-weight-bold mt-9">
            {{
              userInformations
                ? "E-Mail-Adresse bestätigen"
                : "Einloggen zum fortfahren"
            }}
          </h3>
          <h5 class="text-h5 text-secondary font-weight-normal">
            Um Tickets verbindlich
            {{ event.price > 0 ? "bestellen" : "reservieren" }} zu können,
            {{ userInformations ? "" : "logge Dich ein und" }} bestätige bitte
            deine E-Mail-Adresse
          </h5>
        </v-col>
        <v-col cols="12" class="text-center mx-auto" v-if="!userInformations">
          <v-btn
            :elevation="0"
            @click="$store.commit('popLogin', true)"
            class="
              font-weight-bold
              text-xs
              btn-default
              bg-gradient-warning
              py-5
              px-6
              mt-6
              mb-2
              me-2
              ms-auto
            "
            color="primary"
          >
            Einloggen
          </v-btn>
        </v-col>

        <v-col cols="12" class="text-center mx-auto" v-if="!userInformations">
          <h5 class="text-h5 text-secondary font-weight-normal">
            Du hast noch keinen Account?
          </h5>
          <v-btn
            v-if="!userInformations"
            :elevation="0"
            @click="$store.commit('popRegister', true)"
            class="
              font-weight-bold
              text-xs
              btn-default
              bg-gradient-warning
              py-5
              px-6
              mt-6
              mb-2
              me-2
              ms-auto
            "
            color="primary"
          >
            Jetzt Registrieren
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="text-center mx-auto"
          v-if="userInformations && !userInformations.emailVerified"
        >
          <v-btn
            :elevation="0"
            @click="
              $store.commit('accountCreated', true),
                $store.commit('popRegister', true)
            "
            class="
              font-weight-bold
              text-xs
              btn-default
              bg-gradient-warning
              py-5
              px-6
              mt-6
              mb-2
              me-2
              ms-auto
            "
            color="primary"
          >
            E-Mail-Adresse bestätigen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      v-model="successDialog"
      max-width="500px"
      persistent
      v-if="userInformations"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h5 text-typo font-weight-normal justify-center"
          >Bestellung abgeschlossen!</v-card-title
        >
        <v-row justify="center">
          <v-col cols="12" md="8" lg="6" class="my-5">
            <h6 class="text-caption text-uppercase font-weight-600 text-muted">
              Du findest Deine Tickets unter: "Mein Bereich -> Tickets"
            </h6>
          </v-col>
        </v-row>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>

          <v-btn
            @click="completeOrder"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Alles klar!</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="priceLoweredInfoDialog"
      max-width="500px"
      v-if="event.price_lowered_info"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title
          class="pt-0 text-h5 text-typo font-weight-normal justify-center"
          >Ermäßigungshinweise</v-card-title
        >
        <v-row justify="center">
          <v-col cols="12" class="my-5">
            <span class="pr-10" v-html="event.price_lowered_info"></span>
          </v-col>
        </v-row>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>

          <v-btn
            @click="priceLoweredInfoDialog = false"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-normal
              text-capitalize
              btn-ls btn-primary
              bg-gradient-primary
              py-3
              px-6
            "
            >Ok</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
Vue.use(VueSweetalert2);

export default {
  name: "TicketOrder",
  computed: {
    calcTotal() {
      let total = 0;
      this.orderData.participants.forEach((participant) => {
        if (participant.price_lowered) {
          total += this.event.price_lowered;
        } else {
          total += this.event.price;
        }
      });
      return total;
    },
    calcSlots() {
      try {
        return this.event.slots - this.event.entries.length <= 0
          ? 0
          : this.event.slots - this.event.entries.length;
      } catch (error) {
        return 0;
      }
    },
    bookable() {
      if (this.calcSlots > 0) {
        return true;
      } else {
        return false;
      }
    },
    paymentId() {
      return this.$store.getters.paymentId;
    },
    updatingPayment() {
      return this.$store.getters.updatingPayment;
    },
    paymentReady() {
      return this.$store.getters.paymentReady;
    },
    paymentLink() {
      return this.$store.getters.paymentLink;
    },
    paymentState() {
      return this.$store.getters.paymentState;
    },
    loadingEvents() {
      return this.$store.getters.loadingEvents;
    },
    event() {
      return this.$store.getters.event;
    },
    events() {
      return this.$store.getters.events;
    },
    eventComments() {
      return this.$store.getters.eventComments;
    },
    userInformations() {
      return this.$store.getters.userInformations;
    },
    tempParticipants() {
      return this.$store.getters.tempParticipants;
    },
    verifySteps() {
      let steps = {
        step2: true,
        step3: true,
        step4: true,
      };
      var userInformations = this.orderData.userInformations;
      if (
        !userInformations.email ||
        !userInformations.firstname ||
        !userInformations.lastname ||
        !userInformations.street ||
        !userInformations.city ||
        !userInformations.zipcode
      ) {
        steps.step2 = false;
      }

      this.orderData.participants.forEach((participant) => {
        if (
          !participant.name ||
          !participant.street ||
          !participant.city ||
          !participant.zipcode
        ) {
          steps.step3 = false;
        }
      });

      return steps;
    },
  },
  mounted() {
    this.$store.dispatch("getEventByID", this.$route.params.id);
    this.$store.dispatch("getEvents");
    this.$store.dispatch("tryAutoLogin");
    window.scrollTo(0, 0);
    if (this.tempParticipants.length > 0) {
      this.orderData.participants = this.tempParticipants;
      this.orderData.amount = this.tempParticipants.length;
    }
  },
  watch: {
    paymentLink() {
      if (this.paymentLink) {
        window.open(this.paymentLink, "popup", "width=600,height=600");
      }
    },
    userInformations() {
      if (this.userInformations) {
        this.orderData.userInformations = JSON.parse(
          JSON.stringify(this.userInformations)
        );
      } else {
        this.orderData.userInformations = {};
      }
    },
    paymentState() {
      if (this.paymentState) {
        if (this.paymentState == "approved") {
          this.successDialog = true;
          this.cancelAutoUpdate();
        }
      }
    },
    paymentReady() {
      if (this.paymentReady) {
        this.timer = setInterval(this.checkPayment, 2000);
      }
    },
  },
  data() {
    return {
      priceLoweredInfoDialog: false,
      timer: null,
      successDialog: false,
      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
      orderData: {
        amount: 1,
        userInformations: {},
        participants: [
          {
            name: "",
            street: "",
            city: "",
            zipcode: "",
          },
        ],
      },

      e1: 1,
      toggle_exclusive: 2,
      country: [
        "Albania",
        "Algeria",
        "Andorra",
        "Angola",
        "Argentina",
        "Brasil",
      ],
    };
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
  methods: {
    completeOrder() {
      this.$store.commit("paymentReady", null);
      this.$store.commit("paymentLink", null);
      this.$store.commit("paymentId", null);
      this.$store.commit("paymentState", null);
      this.$router.push("/tickets");
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    showSuccessAlert() {
      this.$swal(
        "Bestellung abgeschlossen!",
        'Du findest deine Tickets unter: "Mein Bereich -> Tickets"',
        "success"
      );
    },
    checkPayment() {
      if (this.paymentId) {
        this.$store.dispatch("checkPayment", this.paymentId);
      }
    },
    submitOrder() {
      //   console.log(this.orderData);
      var paymentInfo = {
        transactions: [
          {
            item_list: {
              items: [],
            },
            amount: {
              currency: "EUR",
              total: this.calcTotal.toFixed(2).toString(),
            },
            description: this.event.title,
          },
        ],
      };
      this.orderData.participants.forEach((participant) => {
        paymentInfo.transactions[0].item_list.items.push({
          name:
            participant.name + (participant.price_lowered ? " (ermäßigt)" : ""),
          sku: this.event._id,
          price: participant.price_lowered
            ? this.event.price_lowered.toFixed(2).toString()
            : this.event.price.toFixed(2).toString(),
          tax: participant.price_lowered
            ? (this.event.price_lowered - this.event.price_lowered / 1.19)
                .toFixed(2)
                .toString()
            : (this.event.price - this.event.price / 1.19)
                .toFixed(2)
                .toString(),
          currency: "EUR",
          quantity: 1,
        });
      });

      var eventEntry = {
        event: this.event._id,
        firstname: this.orderData.userInformations.firstname,
        lastname: this.orderData.userInformations.lastname,
        street: this.orderData.userInformations.street,
        email: this.orderData.userInformations.email,
        zip: this.orderData.userInformations.zipcode,
        city: this.orderData.userInformations.city,
        participants: this.orderData.participants,
      };

      var payload = {
        paymentInfo: paymentInfo,
        eventEntry: eventEntry,
      };
      if (this.event.price > 0) {
        console.log(payload);
        this.$store.dispatch("postPayment", payload);
      } else {
        this.$store.dispatch("registerForEvent", payload.eventEntry);
      }
    },
    addAmount() {
      if (
        this.orderData.amount <
        this.event.slots - this.event.entries.length
      ) {
        this.orderData.amount += 1;
      }
      this.orderData.participants.push({
        name: "",
        street: "",
        city: "",
        zipcode: "",
      });
    },
    subAmount() {
      if (this.orderData.amount >= 1) {
        this.orderData.amount -= 1;
      }
      this.orderData.participants.splice(
        this.orderData.participants.length - 1,
        1
      );
    },
    addEl() {
      if (this.e1 == 2) {
        var payload = {
          firstname: this.orderData.userInformations.firstname,
          lastname: this.orderData.userInformations.lastname,
          street: this.orderData.userInformations.street,
          city: this.orderData.userInformations.city,
          zipcode: this.orderData.userInformations.zipcode,
          companyName: this.orderData.userInformations.companyName,
        };
        this.$store.dispatch("updateUserInformations", payload);
      }
      this.e1 += 1;
      if (this.orderData.participants) {
        this.$store.commit("tempParticipants", this.orderData.participants);
      }
    },
    subEl() {
      this.e1 -= 1;
    },
  },
};
</script>

<template>
  <div>
    <loading-overlay v-if="updating" :text="''" />
    <v-container fluid class="px-6 py-6">
      <v-row class="m-0">
        <v-col cols="12" class="pt-0 mb-10 mt-5">
          <!-- <v-card class="mb-30 card card-shadow border-radius-xl py-5">
            <div class="mt-n11 mx-4 card-header position-relative z-index-2">
              <div class="d-block blur-shadow-image">
                <dropzone @newFile="setFile"></dropzone>
              </div>
            </div>
            <div class="card-header-padding">
              <v-row>
                <v-col>
                  <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                    Titelbild hochladen
                  </h5>
                </v-col>
              </v-row>
            </div>
          </v-card> -->
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Quest Informationen
              </h5>
              <v-form>
                <div class="ps-lg-5 mt-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Name der Quest</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        v-model="newQuest.questName"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Beschreibung</label
                      >
                      <v-text-field
                        :rules="rules"
                        required
                        outlined
                        v-model="newQuest.subTitle"
                        class="
                          input-style
                          font-size-input
                          text-light-input
                          placeholder-light
                        "
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <label
                        for=""
                        class="label-color font-weight-600 mb-2 d-block"
                        >Kategorie</label
                      >

                      <v-select
                        :items="[
                          { value: 'structure', text: 'Bauwerk' },
                          { value: 'food', text: 'Essen & Trinken' },
                          { value: 'nature', text: 'Natur & Erholung' },
                          { value: 'sightseeing', text: 'Freizeit & Kultur' },
                          { value: 'miscellaneous', text: 'sonstiges' },
                        ]"
                        value="value"
                        v-model="newQuest.category"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <h5 class="font-weight-bolder text-h5 text-typo mb-0">
                Standort
              </h5>

              <div class="mt-5">
                <GmapMap
                  :center="{
                    lat: newQuest.coordinates.lat,
                    lng:newQuest.coordinates.lon,
                  }"
                  :zoom="10"
                  map-type-id="terrain"
                  style="width: 100%; height: 400px"
                >
                  <GmapMarker
                    :position="{
                      lat: newQuest.coordinates.lat,
                      lng: newQuest.coordinates.lon,
                    }"
                    :clickable="true"
                    :draggable="true"
                    @dragend="showLocation"
                    @click="center = m.position"
                  />
                </GmapMap>

                <v-form>
                  <div class="ps-lg-5 mt-5">
                    <v-row>
                      <v-col cols="12" md="6">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Latitude</label
                        >
                        <v-text-field
                          
                          required
                          outlined
                          v-model="newQuest.coordinates.lat"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Longitude</label
                        >
                        <v-text-field
                          
                          required
                          outlined
                          v-model="newQuest.coordinates.lon"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Region der Quest</label
                        >
                        <v-text-field
                          :rules="rules"
                          required
                          outlined
                          v-model="newQuest.region"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                        >
                        </v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <label
                          for=""
                          class="label-color font-weight-600 mb-2 d-block"
                          >Location Text</label
                        >
                        <v-text-field
                          :rules="rules"
                          required
                          outlined
                          v-model="newQuest.locationText"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                          "
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                </v-form>
              </div>
            </v-card-text>
          </v-card>

          <v-card class="card-shadow border-radius-xl mt-5">
            <v-card-text class="card-padding">
              <v-row class="">
                <v-col>
                  <v-row align="center" justify="space-around">
                    <v-btn
                      elevation="0"
                      height="43"
                      :disabled="checkForm"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-success
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                      color="#5e72e4"
                      small
                      @click="postQuest"
                      >Quest anlegen</v-btn
                    >
                    <v-btn
                      elevation="0"
                      height="43"
                      class="
                        font-weight-bold
                        text-uppercase
                        btn-default
                        bg-gradient-secondary
                        py-2
                        px-6
                        me-2
                        mb-sm-0 mb-2
                      "
                      color="#5e72e4"
                      small
                      @click="$router.push('/admin/quests').catch(() => {})"
                      >Abbrechen</v-btn
                    >
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-snackbar
        top
        v-model="snackbar.visible"
        :color="snackbar.color"
        class="snackbar-shadow"
      >
        <div class="d-flex align-start alert-notify">
          <v-icon size="24" class="text-white me-3 mt-1 material-icons-round"
            >notifications</v-icon
          >
          <p class="mb-0">
            <span class="font-size-root font-weight-600">{{
              snackbar.title
            }}</span>
            <br />
            {{ snackbar.message }}
          </p>
        </div>

        <template v-slot:action="{ attrs }">
          <v-btn
            icon
            elevation="0"
            max-width="136"
            :ripple="false"
            height="43"
            class="font-weight-600 text-capitalize py-3 px-6 rounded-sm"
            color="rgba(255,255,255, .85)"
            @click="snackbar.visible = false"
            v-bind="attrs"
          >
            <v-icon size="13">fas fa-times</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
// import CalendarWidget from "./Widgets/CalendarWidget.vue";
import { VueEditor } from "vue2-editor";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import Dropzone from "./Widgets/Dropzone.vue";
import coreData from "@/mixins/coreDataMixin";
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "NewEvent",
  mixins: [coreData],
  components: {
    VueEditor,
    DatePicker,
    Dropzone,
  },
  data: function () {
    return {
      newEvent: {
        sponsors: [],
        hint: "",
        html: "",
      },
      newQuest: {
        category: '',
        region: '',
        coordinates: {
          lat: 50.883625,
          lon: 10.160221
        },
        experience: 250,
        questName: '',
        subTitle: '',
        locationText: '',
        country: '',
        approved: true,
      },
      file: null
    };
  },

  mounted() {
    if (this.userInformations) {
      if (this.userInformations.companyName) {
        this.newEvent.organizer = this.userInformations.companyName;
      }
      if (this.userInformations.website) {
        this.newEvent.website = this.userInformations.website;
      }
    }
  },

  computed: {
    checkForm() {
      if (
        this.newQuest.category &&
        this.newQuest.region &&
        this.newQuest.coordinates &&
        this.newQuest.experience &&
        this.newQuest.questName &&
        this.newQuest.subTitle &&
        this.newQuest.locationText 
      )
        return false;
      return true;
    },
  },

  methods: {
    showLocation(value){
      console.log(value.latLng.lat())
    
      this.newQuest.coordinates.lat = value.latLng.lat()
      this.newQuest.coordinates.lon = value.latLng.lng()
      
    },
    setFile(file) {
      console.log("file emitted!");
      this.file = file;
    },
    getImage(link) {
      if (!link) return null;
      return process.env.VUE_APP_API_BASEURL + "/" + link;
    },

    postQuest() {
      console.log("postQuest");
      if (this.checkForm)
        return this.SnackbarShow(
          "Danger",
          "Fehler!",
          "Das Formular ist nicht vollständig!"
        );
      this.$store.commit("updating", true);
      return new Promise((resolve, reject) => {
        
        // if (this.newEvent.file) {
        //   formData.append("image", this.newEvent.file);
        // }

        axiosAuth
          .post("quests/", this.newQuest)
          .then(() => {
            resolve(true);
            this.uploadComplete = true;
            this.$store.commit("updating", false);
            this.$store.dispatch("getQuests");
            this.SnackbarShow(
              "Success",
              "Aktion erfolgreich!",
              "Event gespeichert!"
            );
          })
          .catch((error) => {
            this.$store.commit("updating", false);
            this.$store.commit("authError", true);
            this.$store.commit("authMessage", error.response.data);
            this.SnackbarShow(
              "Danger",
              "Fehler!",
              "Etwas ist schief gelaufen..."
            );
            console.log(error.response);
            reject(error.response.data);
          });
      });
    },
  },
};
</script>

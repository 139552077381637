<template>
  <v-row
    class="
      justify-center
      d-flex
      mt-n16
      mx-0
      bg-white
      position-relative
      border-radius-xl
      shadow
      px-4
    "
  >
    <v-col cols="12" md="8">
      <v-container class="mb-15">
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 35px"
        >
          So funktioniert Oelsnitz-Ticket:
        </h2>

        <v-row justify="center">
          <div>
            <v-card @click="$router.push('/')">
              <v-img
                height="100%"
                width="400px"
                :src="first"
                class="img-fluid border-radius-lg"
                :alt="errorImage"
              />
            </v-card>
          </div>
        </v-row>

        <h3
          class="mx-3 h3 font-weight-normal text-center mt-10 text-body"
          style="font-size: 25px"
        >
          1. Veranstaltung aussuchen
        </h3>
        <p class="text-body text-center font-weight-light mb-10 mt-5">
          Wähle eine Veranstaltung von der Homeseite oder der Eventseite aus.
        </p>
        <v-row justify="center">
          <div>
            <v-card @click="$router.push('/')">
              <v-img
                height="100%"
                width="400px"
                :src="second"
                class="img-fluid border-radius-lg"
                :alt="second"
              />
            </v-card>
          </div>
        </v-row>
        <h3
          class="mx-3 h3 font-weight-normal text-center mt-10 text-body"
          style="font-size: 25px"
        >
          2. Auf "Buchen" klicken und das Formular ausfüllen
        </h3>
        <p class="text-body text-center font-weight-light mb-10 mt-5">
          Überprüfe ob noch genug Tickets verfügbar sind und klicke anschließend auf "Buchen".
        </p>
        <v-row justify="center">
          <div>
            <v-card @click="$router.push('/')">
              <v-img
                height="100%"
                width="400px"
                :src="third"
                class="img-fluid border-radius-lg"
                :alt="third"
              />
            </v-card>
          </div>
        </v-row>
        <h3
          class="mx-3 h3 font-weight-normal text-center mt-10 text-body"
          style="font-size: 25px"
        >
          3. Tickets bezahlen (bzw. reservieren)
        </h3>
        <p class="text-body text-center font-weight-light mb-10 mt-5">
          Bei kostenpflichtigen Veranstaltungen kannst du bequem per PayPal bezahlen, bei Reservierungen brauchst du erst an der Abendkasse zu zahlen.
        </p>
        <v-row justify="center">
          <div>
            <v-card @click="$router.push('/')">
              <v-img
                height="100%"
                width="400px"
                :src="fourth"
                class="img-fluid border-radius-lg"
                :alt="fourth"
              />
            </v-card>
          </div>
        </v-row>
        <h3
          class="mx-3 h3 font-weight-normal text-center mt-10 text-body"
          style="font-size: 25px"
        >
          4. QR Code bei der Veranstaltung vorzeigen
        </h3>
        <p class="text-body text-center font-weight-light mb-10 mt-5">
          Deine E-Tickets findest du unter: Mein Bereich -> Tickets <br>
          Wähle die passende Veranstaltung aus und zeige die gebuchten Tickets (QR-Codes) am Einlass vor.
        </p>
        <v-row justify="center">
          <div>
            <v-card @click="$router.push('/')">
              <v-img
                height="100%"
                width="400px"
                :src="fifth"
                class="img-fluid border-radius-lg"
                :alt="fifth"
              />
            </v-card>
          </div>
        </v-row>
        <h3
          class="mx-3 h3 font-weight-normal text-center mb-10 mt-10 text-body"
          style="font-size: 25px"
        >
          5. Habe Spaß auf der Veranstaltung Deiner Wahl!
        </h3>
        <div class="pt-10">
        <h2
          class="mx-3 h1 font-weight-normal text-center mb-10 text-body"
          style="font-size: 35px"
        >
          Noch einfacher geht es mit unserer Smartphone App!
        </h2>
        <appstores-banner></appstores-banner>
      </div>
      </v-container>
    </v-col>
    <sponsors></sponsors>

    <faq></faq>
  </v-row>
</template>
<script>
import Sponsors from "@/components/Widgets/Sponsors.vue";
import faq from "@/components/Widgets/FAQ.vue";
import AppstoresBanner from "@/components/Widgets/AppstoresBanner.vue"

export default {
  name: "Intro",
  components: {
    Sponsors,
    faq,
    AppstoresBanner
  },
  computed: {},
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {},
  data() {
    return {
      tab: "",
      errorImage: require("@/assets/img/undraw/undraw_Page_not_found_re_e9o6.png"),
      first: require("@/assets/img/undraw/undraw_Searching_re_3ra9.png"),
      second: require("@/assets/img/undraw/undraw_Choose_re_7d5a.png"),
      third: require("@/assets/img/undraw/undraw_Online_payments_re_y8f2.png"),
      fourth: require("@/assets/img/undraw/undraw_qr.png"),
      fifth: require("@/assets/img/undraw/undraw_Order_confirmed_re_g0if.png"),
    };
  },
};
</script>
